import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

// Global
import { Header } from '../components/header';
import { Footer } from '../components/footer';

// Views
import { HomeView } from '../views/home';
import { AboutView } from '../views/about';
import { ResourcesView } from '../views/resources';
import { EFPDirectoryView } from '../views/efp-directory';
import { ContactUsView } from '../views/contact-us';

// Routes
import {
  HOME_ROUTE,
  ABOUT_ROUTE,
  RESOURCES_ROUTE,
  EFP_DIR_ROUTE,
  CONTACT_ROUTE,
} from '../constants/routes';

// Utils
import ScrollToTop from '../utils/scroll-top';

const ApplicationWrapper = styled.div`
  overflow: hidden;
`;

export default () => (
  <ApplicationWrapper>
    <ScrollToTop>
      <Header />
      <Switch>
        <Route
          path={HOME_ROUTE}
          exact
          component={HomeView}
        />
        <Route
          path={ABOUT_ROUTE}
          component={AboutView}
        />
        <Route
          path={RESOURCES_ROUTE}
          component={ResourcesView}
        />
        <Route
          path={EFP_DIR_ROUTE}
          component={EFPDirectoryView}
        />
        <Route
          path={CONTACT_ROUTE}
          component={ContactUsView}
        />
      </Switch>
      <Footer />
    </ScrollToTop>
  </ApplicationWrapper>
);
