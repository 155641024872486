// @flow

export const AWS_API_URL = 'https://u0qb73cddh.execute-api.us-east-1.amazonaws.com/default/contactUs';

export const CONTACT_FORM_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'x-api-key': process.env.REACT_APP_AWS_KEY,
};

export const GMAP_API_KEY = 'AIzaSyDdemVBSH6lj-l71MjGMDBsFvJNbt9l390';
