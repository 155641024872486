// @flow

import React from 'react';
import styled from 'styled-components';
import { media } from '../../theme';

const bubbleSize = '250px';
const bubbleSizeMobile = '200px';

const BubbleWrap = styled.div`
  width: 130%;
  display: block;

  ${p => (p.order === 2
    ? `margin: ${p.theme.setSpacing(-2)}px ${p.theme.setSpacing(-6)}px;`
    : `margin: 0 ${p.theme.setSpacing(-6)}px;`
  )}

  ${media.tablet`
    display: inline-block;
    width: auto;
    margin: 0;
  `}
`;

const BubbleOuter = styled.div`
  width: ${bubbleSizeMobile};
  height: ${bubbleSizeMobile};
  position: relative;
  display: block;
  flex-shrink: 0;
  border-radius: 50%;
  border: 3px solid ${p => p.theme.colors.borderQuaternary};
  text-align: center;
  align-content: middle;

  ${p => (p.order === 2
    ? 'margin-left: auto;'
    : 'margin-right: auto;'
  )}

  ${media.tablet`
    display: inline-block;
    width: ${bubbleSizeMobile};
    height: ${bubbleSizeMobile};
    margin: 0;
  `}

  ${media.desktop`
    display: inline-block;
    width: ${bubbleSize};
    height: ${bubbleSize};
    margin: 0;
  `}
`;

const BubbleInner = styled.div`
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate( -50%, -50%);
`;

const BubbleNumber = styled.p`
  display: inline-block;
  color: ${p => p.theme.colors.textTertiary};
  font-weight: ${p => p.theme.fontWeight.bold};
  font-size: ${p => p.theme.fontSize.gigantic}px;
  margin: 0;
`;

const BubbleText = styled.p`
  display: inline-block;
  color: ${p => p.theme.colors.textSecondary};
  font-weight: ${p => p.theme.fontWeight.thin};
  font-size: ${p => p.theme.fontSize.large}px;
  margin: 0 ${p => p.theme.setSpacing(1 / 2)}px;
`;

const BubbleNewLineText = styled(BubbleText)`
  display: block;
  width: 100%;
  margin: 0;
`;

type Props = {
  fields: {
    firstNumber: string,
    middleText: string,
    secondNumber: string,
    bottomText: string,
    order: number,
  }
}

export const Bubble = (props: Props) => {
  const {
    fields: {
      firstNumber,
      middleText,
      secondNumber,
      bottomText,
      order,
    },
  } = props;

  return (
    <BubbleWrap order={order}>
      <BubbleOuter order={order}>
        <BubbleInner>
          <BubbleNumber>{firstNumber}</BubbleNumber>
          <BubbleText>{middleText}</BubbleText>
          <BubbleNumber>{secondNumber}</BubbleNumber>
          <BubbleNewLineText>{bottomText}</BubbleNewLineText>
        </BubbleInner>
      </BubbleOuter>
    </BubbleWrap>
  );
};
