// @flow
import React, { PureComponent } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';
import { media } from '../../theme';
import chevronWhiteDown from '../../assets/images/icons/chevron-white-down.svg';

const FAQContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  cursor: pointer;
  user-select: disabled;
  border-top: 2px solid ${p => p.theme.colors.borderTertiary};
  padding: ${p => p.theme.setSpacing(2)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(3)}px 0;
  `}
`;

const FAQTitle = styled.h2`
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.theme.fontSize.small}px;
  font-weight: ${p => p.theme.fontWeight.semiBold};
  line-height: ${p => p.theme.lineHeight.medium};
  color: ${p => p.theme.colors.textSecondary};
  margin-top: 0;
  cursor: pointer;
  padding-right: ${p => p.theme.setSpacing(3)}px;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.medium}px;
    max-width: ${p => p.theme.setSpacing(80)}px;

    &:hover {
      opacity: .8;
    }
  `}
`;

const FAQIcon = styled.img`
  align-self: flex-start;
  transition: all .2s;
  cursor: pointer;
  ${p => (p.answerShown
    ? `
      transform: rotate(180deg);
    `
    : ''
  )}

  ${media.desktop`
    &:hover {
      opacity: .8;
    }
  `}
`;

const FAQContentBlock = styled.div`
  width: 100%;
  transition: all .2s ease-in;
  max-height: ${p => (p.answerShown ? '1000px' : '0px')};
  overflow: hidden;

  ${media.desktop`
      max-height: ${p => (p.answerShown ? '500px' : '0px')};
  `}
`;

const FAQTitleBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const FAQDescription = styled(ReactMarkdown)`
  * {
    font-family: ${p => p.theme.fontFamily};
    font-size: ${p => p.theme.fontSize.extraSmall}px;
    color: ${p => p.theme.colors.textSecondary};
    line-height: ${p => p.theme.lineHeight.large};
    font-weight: ${p => p.theme.fontWeight.normal};
    margin-top: 0;
    margin-bottom: ${p => p.theme.setSpacing(5)}px;
    max-width: 900px;
  }
  ${p => p.theme.utils.resetLinkColor(p.theme.colors.textSecondary)};

  ${media.desktop`
    a {
      &:hover {
        opacity: .8;
      }
    }
    `}
`;

type Props = {
  question: string,
  response: string,
}

type State = {
  answerShown: boolean,
};

export class Question extends PureComponent<Props, State> {
  state = {
    answerShown: false,
  };

  handleQuestionClick = () => {
    const { answerShown } = this.state;

    this.setState(() => ({
      answerShown: !answerShown,
    }));
  }

  render() {
    const { answerShown } = this.state;
    const { question, response } = this.props;

    return (
      <FAQContainer>
        <FAQTitleBlock onClick={this.handleQuestionClick}>
          <FAQTitle>{ question }</FAQTitle>
          <FAQIcon src={chevronWhiteDown} answerShown={answerShown} alt='arrow-icon' />
        </FAQTitleBlock>
        <FAQContentBlock answerShown={answerShown}>
          <FAQDescription linkTarget='_blank'>{ response }</FAQDescription>
        </FAQContentBlock>
      </FAQContainer>
    );
  }
}
