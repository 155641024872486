// @flow

import React from 'react';
import styled from 'styled-components';
import { media } from '../../theme';

const DropdownLabelFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: ${p => p.theme.setSpacing(3)}px 0;

  ${media.desktop`
    flex-wrap: nowrap;
  `}
`;

const FormLabel = styled.label`
  font-size: ${p => p.theme.fontSize.small}px;
  font-weight: ${p => p.theme.fontWeight.thin};
  color: ${p => p.theme.colors.textPrimary};
  text-align: left;
  width: 100%;
  margin-bottom: ${p => p.theme.baseSpacing}px;

  ${media.desktop`
    width: 20%;
    text-align: right;
    margin-bottom: 0;
  `}
`;

const PrimarySelect = styled.select`
  display: inline-block;
  width: 100%;
  height: 30px;
  border-color: ${p => p.theme.colors.borderPrimary};

  ${media.desktop`
    width: 65%;
    margin-left: ${p => p.theme.setSpacing(3)}px;
  `}
`;

type Props = {
  onChange: Function,
  id: string,
  label: string,
  value: string,
  options: Array<string>,
}

export const FormDropdown = (props: Props) => {
  const {
    id,
    label,
    value,
    onChange,
    options,
  } = props;
  return (
    <DropdownLabelFlex>
      <FormLabel htmlFor={id}>{label}</FormLabel>
      <PrimarySelect onChange={onChange} value={value}>
        <option value='' />
        {options.map(option => (
          <option key={option} value={option}>{option}</option>
        ))}
      </PrimarySelect>
    </DropdownLabelFlex>
  );
};
