// @flow
import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import LogoMobile from '../../assets/images/logo-mobile.png';
import { HOME_ROUTE } from '../../constants/routes';
import { LOGIN_URL } from '../../constants/header';
import { LoginButton, ButtonWrapper } from './login-button';
import { media } from '../../theme';
import menuOpenIcon from '../../assets/images/icons/mobile-menu-open.svg';
import menuCloseIcon from '../../assets/images/icons/mobile-menu-close.svg';
import { handleNavigationClick } from '../../utils/analytics';

const DesktopMenu = styled.div`
  display: none;
  margin-left: auto;
  align-items: flex-end;
  flex-wrap: wrap;

  ${media.desktop`
    display: flex;
  `}
`;

const MenuListWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

const MenuItem = styled(NavLink)`
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.theme.fontSize.small}px;
  font-weight: ${p => p.theme.fontWeight.thin};
  text-decoration: none;
  text-align: left;
  color: ${p => p.theme.colors.textPrimary};
  margin-left: ${p => p.theme.setSpacing(4)}px;

  &:first-of-type {
    margin-left: 0;
  }

  ${media.desktop`
    &:hover {
      color: ${p => p.theme.colors.textQuinary};
    }
    &.active {
      color: ${p => p.theme.colors.textQuinary};
    }
  `}
`;

const MobileMenuButton = styled.button`
  width: ${p => p.theme.setSpacing(4)}px;
  height: ${p => p.theme.setSpacing(2.5)}px;
  position: absolute;
  top: ${p => p.theme.setSpacing((1 / 2))}px;
  right: ${p => p.theme.setSpacing(3)}px;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;

  ${media.desktop`
    display: none;
  `}
`;

const MobileMenuIcon = styled.img`
  width: 100%;
  height: 100%;
`;

const MobileMenu = styled.div`
  display: ${p => (p.navOpen ? 'block' : 'none')};
  padding: ${p => p.theme.setSpacing(5)}px;
  background-color: ${p => p.theme.colors.backgroundTertiary};
  position: fixed;
  top: 64px;
  left: 0;
  bottom: 0;
  width: 100vw;
  height: 100%;
  z-index: 200;

  ${media.desktop`
    display: none;
  `}
`;

const MobileMenuItem = styled(MenuItem)`
  color: ${p => p.theme.colors.textSecondary};
  display: block;
  margin-left: 0;
  font-size: ${p => p.theme.fontSize.medium}px;
  line-height: ${p => p.theme.lineHeight.large};
  margin-bottom: ${p => p.theme.setSpacing(3)}px;

  &.active {
    font-weight: ${p => p.theme.fontWeight.semiBold};
  }
`;

const MobileMenuLogin = styled.a`
  font-family: ${p => p.theme.fontFamily};
  font-weight: ${p => p.theme.fontWeight.semiBold};
  border: 1px solid ${p => p.theme.colors.textSecondary};
  text-decoration: none;
  text-align: center;
  color: ${p => p.theme.colors.textSecondary};
  display: block;
  margin-left: 0;
  font-size: ${p => p.theme.fontSize.medium}px;
  line-height: ${p => p.theme.lineHeight.large};
  margin-bottom: ${p => p.theme.setSpacing(3)}px;
`;

const LogoImg = styled.img`
  display: none;

  ${media.desktop`
    display: inline-block;
    width: ${p => p.theme.setSpacing(37)}px;
    height: ${p => p.theme.setSpacing(13)}px;
  `}
`;

const LogoImgMobile = styled(LogoImg)`
  display: inline-block;
  width: 250px;

  ${media.desktop`
    display: none;
  `}
`;

type Props = {
  items: Array<any>,
}

type State = {
  navOpen: boolean,
  activeRoute: string,
}

export class NavMenu extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      navOpen: false,
      activeRoute: window.location.pathname,
    };
  }

  handleMenuOpenClick = () => {
    const { navOpen } = this.state;

    this.setState(() => ({
      navOpen: !navOpen,
    }));
  }

  handleCloseMenu = () => this.setState(() => ({
    navOpen: false,
  }));

  handleClick = (route: string, url: string) => {
    this.setState(() => ({
      activeRoute: url,
    }));
    handleNavigationClick(route);
  }

  isItemActive = (route: string) => {
    const { activeRoute } = this.state;
    return activeRoute === route;
  }

  render() {
    const { items } = this.props;
    const { navOpen } = this.state;

    const menuIcon = navOpen ? menuCloseIcon : menuOpenIcon;

    return (
      <Fragment>
        <NavLink
          to={HOME_ROUTE}
          onClick={() => {
            this.handleCloseMenu();
            this.handleClick('Logo', HOME_ROUTE);
          }}
        >
          <LogoImg src={Logo} alt='Logo' />
          <LogoImgMobile src={LogoMobile} alt='Logo' />
        </NavLink>
        <MobileMenuButton onClick={this.handleMenuOpenClick}>
          <MobileMenuIcon alt='nav-menu' src={menuIcon} />
        </MobileMenuButton>
        <MobileMenu navOpen={navOpen}>
          <MobileMenuLogin
            href={LOGIN_URL}
            target='_blank'
            onClick={() => {
              this.handleCloseMenu();
              handleNavigationClick('Login');
            }}
          >
            Login
          </MobileMenuLogin>
          {items.map((item) => {
            const { hash } = item;

            return hash
              ? (
                <MobileMenuItem
                  as='a'
                  key={item.text}
                  href={`${item.url}#${hash}`}
                  onClick={() => {
                    this.handleCloseMenu();
                    this.handleClick(item.text, item.url);
                  }}
                >
                  {item.text}
                </MobileMenuItem>
              )
              : (
                <MobileMenuItem
                  key={item.text}
                  to={item.url}
                  isActive={() => this.isItemActive(item.url)}
                  onClick={() => {
                    this.handleCloseMenu();
                    this.handleClick(item.text, item.url);
                  }}
                >
                  {item.text}
                </MobileMenuItem>
              );
          })}
        </MobileMenu>
        <DesktopMenu>
          <ButtonWrapper
            href={LOGIN_URL}
            target='_blank'
            onClick={() => handleNavigationClick('Login')}
          >
            <LoginButton>Login</LoginButton>
          </ButtonWrapper>
          <MenuListWrapper>
            {items.map((item) => {
              const { hash } = item;

              return hash
                ? (
                  <MenuItem
                    as='a'
                    key={item.text}
                    href={`${item.url}#${hash}`}
                    onClick={() => handleNavigationClick(item.text)}
                  >
                    {item.text}
                  </MenuItem>
                )
                : (
                  <MenuItem
                    key={item.text}
                    to={item.url}
                    onClick={() => this.handleClick(item.text, item.url)}
                    isActive={() => this.isItemActive(item.url)}
                    activeClassName='active'
                  >
                    {item.text}
                  </MenuItem>
                );
            })}
          </MenuListWrapper>
        </DesktopMenu>
      </Fragment>
    );
  }
}
