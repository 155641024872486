// @flow

import React from 'react';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { media } from '../../theme';
import phoneIcon from '../../assets/images/icons/phone.svg';
import markerSmall from '../../assets/images/icons/marker-small.svg';

const ResultWrapper = styled.div`
  padding: ${p => p.theme.baseSpacing}px ${p => p.theme.setSpacing(2)}px;
  cursor: pointer;
  ${p => (p.isActive
    ? `background-color: ${p.theme.colors.backgroundQuaternary};`
    : ''
  )}
  ${p => p.theme.fullWidthMobileOnly}

  ${media.tablet`
    border-top: 1px solid ${p => p.theme.colors.backgroundPrimary};
    margin: 0;
    padding: ${p => p.theme.setSpacing(3)}px;
  `}

  ${media.desktop`
    ${p => (p.isActive
    ? ''
    : `
      &:hover {
        background-color: ${p.theme.colors.backgroundQuaternaryHover};
      }
    `
  )}
  `}
`;

const ResultTitle = styled.h3`
  margin: 0;
  font-size: ${p => p.theme.fontSize.small}px;
  color: ${p => p.theme.colors.textSecondary};
  font-weight: ${p => p.theme.fontWeight.semiBold};

  ${media.tablet`
    font-size: ${p => p.theme.fontSize.medium}px;
  `}
`;

const ResultAddress = styled.p`
  margin: ${p => p.theme.baseSpacing}px 0;
  font-size: ${p => p.theme.fontSize.tiny}px;
  color: ${p => p.theme.colors.textSecondary};
  font-weight: ${p => p.theme.fontWeight.thin};
  line-height: ${p => p.theme.lineHeight.medium};

  ${media.tablet`
    font-size: ${p => p.theme.fontSize.extraSmall}px;
  `}
`;

const ResultHours = styled(ResultAddress)`
  margin: 0;
`;

const ResultHoursWrapper = styled.div`
  margin: ${p => p.theme.baseSpacing}px 0;
`;

const ResultIcon = styled.img`
  width: 10px;
  height: 10px;
  margin-right: ${p => p.theme.baseSpacing}px;

  ${media.desktop`
    width: 16px;
    height: 16px;
    `}
`;

type Props = {
  entry: Object,
  isActive: boolean,
  onResultClick: Function,
}

export const MapSingleResult = (props: Props) => {
  const {
    entry,
    entry: {
      accountName,
      address,
      phone,
      programHours,
    },
    isActive,
    onResultClick,
  } = props;

  const programHoursArray = programHours && programHours.split(',');

  return (
    <ResultWrapper
      isActive={isActive}
      onClick={() => onResultClick(entry)}
    >
      <ResultTitle>{accountName}</ResultTitle>
      <ResultHoursWrapper>
        { programHoursArray && programHoursArray.map(block => (
          <ResultHours key={uniqid()}>{block.replace(':', ': ')}</ResultHours>
        ))
        }
      </ResultHoursWrapper>
      <ResultAddress>
        <ResultIcon src={markerSmall} />
        {address}
      </ResultAddress>
      <ResultAddress>
        <ResultIcon src={phoneIcon} />
        {phone}
      </ResultAddress>
    </ResultWrapper>
  );
};
