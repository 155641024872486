// @flow

import React from 'react';
import styled from 'styled-components';
import { media } from '../../theme';

const ResultButton = styled.div`
  height: 56px;
  width: 50%;
  text-align: center;
  cursor: pointer;
  font-size: ${p => p.theme.fontSize.small}px;
  padding: ${p => p.theme.setSpacing(2)}px;
  ${p => (p.activeTab
    ? `
      background-color: ${p.theme.colors.buttonPrimaryColor};
      color: ${p.theme.colors.buttonPrimaryText};
    `
    : `
      background-color: ${p.theme.colors.buttonPrimaryText};
      color: ${p.theme.colors.buttonPrimaryColor};

      &:hover {
        background-color: ${p.theme.colors.borderPrimary};
      }

    `
  )}

  ${media.tablet`
    width: 192px;
  `}
`;

type Props = {
  activeTab: boolean,
  label: string,
  onClick: Function,
}

export const ResultsTab = (props: Props) => {
  const { activeTab, label, onClick } = props;
  return (
    <ResultButton
      activeTab={activeTab}
      onClick={() => onClick(label)}
    >
      {label}
    </ResultButton>
  );
};
