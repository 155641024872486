// @flow
import styled, { css } from 'styled-components';

const buttonPadding = css`
  ${p => p.theme.setSpacing(1.5)}px ${p => p.theme.setSpacing(8)}px;
`;

export const ButtonWrapper = styled.a`
  width: 100%;
  align-self: flex-start;
  text-decoration: none;
  outline: none;
`;

export const LoginButton = styled.button`
  color: ${p => p.theme.colors.buttonSecondaryText};
  background-color: ${p => p.theme.colors.buttonSecondaryColor};
  border: 1px solid ${p => p.theme.colors.buttonSecondaryText};
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.theme.fontSize.small}px;
  font-weight: ${p => p.theme.fontWeight.regular};
  padding: ${buttonPadding};
  align-self: flex-end;
  justify-self: center;
  margin-left: auto;
  display: block;
  cursor: pointer;
  outline: none;

  &:hover {
    color: ${p => p.theme.colors.textSecondary};
    background-color: ${p => p.theme.colors.buttonSecondaryText};
  }
`;
