// @flow

import React from 'react';
import styled from 'styled-components';
import { media } from '../../theme';

const ResultsHeading = styled.h2`
  color: ${p => p.theme.colors.textSecondary};
  font-size: ${p => p.theme.fontSize.large}px;
  font-weight: ${p => p.theme.fontWeight.semiBold};
`;

const ListColumnsWrapper = styled.div`
  display: none;

  ${media.tablet`
    display: flex;
  `}
`;

const NameColumnDiv = styled.div`
  width: 50%;
`;

const DetailColumnDiv = styled.div`
  width: 25%;
`;

export const ListColumns = () => (
  <ListColumnsWrapper>
    <NameColumnDiv>
      <ResultsHeading>Program Name</ResultsHeading>
    </NameColumnDiv>
    <DetailColumnDiv>
      <ResultsHeading>Borough</ResultsHeading>
    </DetailColumnDiv>
    <DetailColumnDiv>
      <ResultsHeading>Program Type</ResultsHeading>
    </DetailColumnDiv>
  </ListColumnsWrapper>
);
