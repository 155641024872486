// @flow
import ReactGA from 'react-ga';

export const handleNavigationClick = (item: string) => {
  ReactGA.event({
    category: 'Navigation',
    action: 'Clicked Nav Menu',
    label: item,
  });
};

export const handleOrganizationClick = (org: string) => {
  ReactGA.event({
    category: 'Organizations',
    action: 'Clicked Organization Link',
    label: org,
  });
};

export const handleFooterClick = (link: string) => {
  ReactGA.event({
    category: 'Navigation',
    action: 'Clicked Footer Link',
    label: link,
  });
};
