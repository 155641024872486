// @flow
import React, { PureComponent } from 'react';
import styled, { css } from 'styled-components';
import { Button } from '../button';
import { media } from '../../theme';
import { FullWidthSection } from '../content-container';
import chevronWhiteDown from '../../assets/images/icons/chevron-white-down.svg';

export const createID = (str: string) => {
  let newStr = str.replace(/\s+/g, '-').toLowerCase();
  if (newStr.endsWith('-')) {
    newStr = newStr.substring(0, newStr.length - 1);
  }

  return newStr;
};

const DesktopMenuWrapper = styled.div`
  display: none;

  ${media.desktop`
    display: block;

    ${p => p.isSticky && css`
      position: fixed;
      top: 0;
      min-width: 200px;
      max-width: 200px;
    `}
  `}
`;

const MobileMenuWrapper = styled(FullWidthSection)`
  display: block;

  ${p => p.isSticky && css`
    position: fixed;
    top: 48px;
    margin: 0;
    right: 0;
    left: 0;
    z-index: 10;
  `}

  ${media.desktop`
    display: none;
  `}
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  height: 40px;
`;

const CategoriesHeading = styled.h3`
  color: ${p => p.theme.colors.textQuaternary};
  font-size: ${p => p.theme.fontSize.large}px;
  border-bottom: 1px solid ${p => p.theme.colors.borderSecondary};
  padding-bottom: ${p => p.theme.setSpacing(3)}px;
`;

const CategoryItem = styled.div`
  font-size: ${p => p.theme.fontSize.small}px;
  color: ${p => p.theme.colors.textPrimary};
  font-weight: ${p => p.theme.fontWeight.thin};
  text-decoration: none;
  margin: ${p => p.theme.setSpacing(4)}px 0;
  display: block;
  cursor: pointer;

  &:hover {
    color: ${p => p.theme.colors.textQuinary};
  }

  ${p => p.isActive && css`
    color: ${p.theme.colors.textQuinary};
  `}
`;

const OptionsWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Option = styled(Button)`
  color: ${p => p.theme.colors.textSecondary};
  background-color: ${p => p.theme.colors.backgroundTertiary};
  padding: 10px 40px;
`;

const ActiveOption = styled(Option)`
  margin-left: 0;
  margin-top: ${p => p.theme.setSpacing(2)}px;
  padding: 10px 40px;

  ${media.desktop`
    margin-top: 0;
    margin-bottom: 0;
  `}
`;

const Select = styled.div`
  display: ${p => (p.menuOpen ? 'block' : 'none')};
  position: absolute;
  background-color: ${p => p.theme.colors.backgroundTertiary};
  padding: ${p => p.theme.setSpacing(2)}px 0;
  z-index: 10;
  width: 100%;
  border: 1px solid ${p => p.theme.colors.borderPrimary};
  border-left: none;
  border-right: none;
`;

const SingleOption = (
  activeSection: Object,
  menuOpen: boolean,
  onClick: Function,
) => {
  const { fields } = activeSection;
  const renderedText = (fields && fields.categoryName) || 'All Sections';

  return (
    <ActiveOption
      key={renderedText}
      icon={chevronWhiteDown}
      onClick={onClick}
      menuOpen={menuOpen}
    >
      {renderedText}
    </ActiveOption>
  );
};

const renderAllOptions = (
  categories: Array<Object>,
  menuOpen: boolean,
  onClick: Function,
) => (
  <Select menuOpen={menuOpen}>
    {categories.map(category => (
      <Option
        onClick={() => onClick(category)}
        key={category.fields && category.fields.categoryName}
      >
        {category.fields && category.fields.categoryName}
      </Option>
    ))}
  </Select>
);

type Props = {
  menuItems: Array<Object>,
  isSidebarSticky: boolean,
  isMobileSidebarSticky: boolean,
  activeSidebarItem: string,
};

type State = {
  menuOpen: boolean,
  activeSection: Object,
  activeSidebarItem: string,
};

export class CategoryMenu extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      menuOpen: false,
      activeSection: {},
      activeSidebarItem: props.activeSidebarItem || '',
    };
  }

  componentWillReceiveProps(nextProps: Props) {
    const { activeSidebarItem } = this.props;

    if (nextProps.activeSidebarItem !== activeSidebarItem) {
      this.setState(() => ({
        activeSidebarItem: nextProps.activeSidebarItem,
      }));
    }
  }

  scrollToMyRef = (myRef: string) => {
    const elem = document.getElementById(myRef) || null;
    const elemTop = elem && elem.offsetTop;
    const mobileDifferenceDistance = (window.outerWidth > 414) ? 0 : 100;

    if (elemTop) {
      window.scrollTo(0, (elemTop - mobileDifferenceDistance));
    }

    this.setState(() => ({
      activeSidebarItem: `${myRef}-selector`,
    }));
  };

  handleMenuOpenClick = (active: Object) => {
    const { menuOpen, activeSection } = this.state;
    const { fields } = active;

    if (menuOpen && activeSection && fields) {
      const { fields: { categoryName } } = active;
      const catId = categoryName && createID(categoryName);
      this.scrollToMyRef(catId);
    }

    this.setState(() => ({
      menuOpen: !menuOpen,
      activeSection: active || activeSection,
    }));
  }

  render() {
    const {
      menuOpen,
      activeSection,
      activeSidebarItem,
    } = this.state;
    const {
      menuItems,
      isSidebarSticky,
      isMobileSidebarSticky,
    } = this.props;

    return (
      <Container>
        <DesktopMenuWrapper
          id='category-sidebar'
          isSticky={isSidebarSticky}
        >
          <CategoriesHeading>
            Categories
          </CategoriesHeading>
          {(menuItems.length > 0) && menuItems.map(({ fields }) => {
            const catId = createID(fields.categoryName);
            const isActive = `${catId}-selector` === activeSidebarItem;

            return (
              <CategoryItem
                id={`${catId}-selector`}
                key={fields.categoryName}
                isActive={isActive}
                onClick={() => this.scrollToMyRef(catId)}
              >
                {fields.categoryName}
              </CategoryItem>
            );
          })}
        </DesktopMenuWrapper>
        <MobileMenuWrapper
          id='category-sidebar-mobile'
          isSticky={isMobileSidebarSticky}
        >
          <OptionsWrapper>
            {SingleOption(activeSection, menuOpen, this.handleMenuOpenClick)}
            {(menuItems.length > 1) && renderAllOptions(
              menuItems,
              menuOpen,
              section => this.handleMenuOpenClick(section),
            )}
          </OptionsWrapper>
        </MobileMenuWrapper>
      </Container>
    );
  }
}
