// @flow
import styled from 'styled-components';

export const ContentContainer = styled.div`
  ${p => p.theme.contentContainer};
`;

export const FullWidthSection = styled.div`
  ${p => p.theme.fullWidthSection};
`;
