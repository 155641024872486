// @flow

import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { media } from '../../theme';
import { ContentContainer } from '../content-container';
import { AboutDiagram } from './diagram';

const IntroContainer = styled.div`
  background-color: ${p => p.theme.colors.backgroundSecondary};
  padding: ${p => p.theme.setSpacing(6)}px 0 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(8)}px 0;
  `}
`;

const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 2px solid ${p => p.theme.colors.borderTertiary};
  padding: ${p => p.theme.setSpacing(4)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(6)}px 0 0;
    flex-wrap: nowrap;
  `}
`;

const TitleBlock = styled.div`
  width: 100%;

  ${media.desktop`
    width: 50%;
  `}
`;

const Title = styled.h2`
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.theme.fontSize.large}px;
  font-weight: ${p => p.theme.fontWeight.regular};
  line-height: ${p => p.theme.lineHeight.extraSmall};
  color: ${p => p.theme.colors.textSecondary};
  margin-top: 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.huge}px;
    font-weight: ${p => p.theme.fontWeight.semiBold};
  `}
`;

const TitleSecondary = styled(Title)`
  ${p => (p.noMarginBottom
    ? 'margin-bottom: 0'
    : ''
  )}
  ${media.desktop`
    margin: 0;
    font-size: ${p => p.theme.fontSize.medium}px;
    font-weight: ${p => p.theme.fontWeight.regular};
    line-height: ${p => p.theme.lineHeight.medium};
  `}
`;

const EmphasisText = styled.p`
  color: ${p => p.theme.colors.textTertiary};
  font-weight: ${p => p.theme.fontWeight.bold};
  font-size: ${p => p.theme.fontSize.huge}px;
  margin: ${p => p.theme.setSpacing(1 / 2)}px 0;

  ${media.desktop`
    margin: ${p => p.theme.setSpacing(2)}px 0;
  `}
`;

const SubtitleBlock = styled.div`
  width: 100%;

  ${media.desktop`
    width: 46%;
  `}
`;

const Subtitle = styled(ReactMarkdown)`
  * {
    font-family: ${p => p.theme.fontFamily};
    font-size: ${p => p.theme.fontSize.extraSmall}px;
    color: ${p => p.theme.colors.textSecondary};
    line-height: ${p => p.theme.lineHeight.large};
    font-weight: ${p => p.theme.fontWeight.normal};
    margin-top: 0;
    margin-bottom: ${p => p.theme.setSpacing(2)}px;

    ${media.desktop`
      margin-bottom: ${p => p.theme.setSpacing(5)}px;

      a {
        &:hover {
          opacity: .8;
        }
      }
    `}
  }
  ${p => p.theme.utils.resetLinkColor(p.theme.colors.textSecondary)};
`;

type StatProps = {
  fields: {
    topText: string,
    emphasisText: string,
    bottomText: string,
  }
}

const FeaturedStat = (props: StatProps) => {
  const {
    fields: {
      topText,
      emphasisText,
      bottomText,
    },
  } = props;

  return (
    <TitleBlock>
      <TitleSecondary noMarginBottom>{topText}</TitleSecondary>
      <EmphasisText>{emphasisText}</EmphasisText>
      <TitleSecondary>
        {bottomText}
      </TitleSecondary>
    </TitleBlock>
  );
};

type Props = {
  hungerStatistics: Object,
}

export const AboutIntro = (props: Props) => {
  const {
    hungerStatistics: {
      fields: {
        title,
        topRightParagraph,
        bottomRightParagraphs,
        featuredStatistic,
        bubbleDiagram,
      },
    },
  } = props;

  return (
    <IntroContainer>
      <ContentContainer>
        <SectionContainer>
          <TitleBlock>
            <Title>
              {title}
            </Title>
          </TitleBlock>
          <SubtitleBlock>
            <Subtitle linkTarget='_blank'>
              {topRightParagraph}
            </Subtitle>
          </SubtitleBlock>
        </SectionContainer>
        { (bubbleDiagram && bubbleDiagram.fields) && <AboutDiagram bubbleDiagram={bubbleDiagram} />}
        <SectionContainer>
          {featuredStatistic && <FeaturedStat {...featuredStatistic} />}
          <SubtitleBlock>
            <Subtitle linkTarget='_blank'>
              {bottomRightParagraphs}
            </Subtitle>
          </SubtitleBlock>
        </SectionContainer>
      </ContentContainer>
    </IntroContainer>
  );
};
