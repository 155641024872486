// @flow
import React from 'react';
import styled from 'styled-components';
import { Button } from './button';
import whiteArrow from '../assets/images/icons/white-arrow.svg';
import blueArrow from '../assets/images/icons/arrow-right-blue.svg';
import { media } from '../theme';

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  ${media.desktop`
    width: auto;
  `}
`;

const ExternalLinkButton = styled(Button)`
  font-size: ${p => p.theme.fontSize.small}px;
  line-height: ${p => p.theme.lineHeight.extraSmall};
`;

type Props = {
  url: string,
  text: string,
  isSecondary?: boolean,
  isTertiary?: boolean,
}

export const ExternalLink = (props: Props) => {
  const {
    url,
    text,
    isSecondary,
    isTertiary,
  } = props;
  return (
    <ButtonWrapper>
      <ExternalLinkButton
        href={url}
        target='_blank'
        download
        icon={isTertiary ? blueArrow : whiteArrow}
        isInverted={isTertiary}
        isSmall={isSecondary}
      >
        {text}
      </ExternalLinkButton>
    </ButtonWrapper>
  );
};

ExternalLink.defaultProps = {
  isSecondary: false,
  isTertiary: false,
};
