// @flow
import React, { Fragment, PureComponent } from 'react';
import { fetchHome } from '../../services/api';
import { News } from './news';
import { HeroBanner } from '../hero-banner';
import { LoaderComponent } from '../loader';
import { renderDefaultErrorModal } from '../error-modal';
import bannerIllustration from '../../assets/images/gifs/home_banner.gif';
import bannerIllustrationMobile from '../../assets/images/gifs/home_mobile_banner.gif';

type State = {
  introParagraph: string,
  isLoading: boolean,
  news: Array<Object>,
  error: boolean,
}

export class HomePage extends PureComponent<{}, State> {
  state = {
    introParagraph: '',
    isLoading: true,
    news: [],
    error: false,
  }

  componentDidMount() {
    fetchHome()
      .then((response) => {
        const { items } = response;
        const { fields } = items[0];
        this.setPage(
          fields.introParagraph,
          fields.news,
        );
      })
      .catch(this.catchError);
  }

  setPage = (
    introParagraph: string,
    news: Array<Object>,
  ) => this.setState(() => ({
    introParagraph,
    news,
    isLoading: false,
  }));

  catchError = () => this.setState(() => ({ error: true, isLoading: false }))

  closeModal = () => this.setState(() => ({ error: false }));

  renderLoadedContent = () => {
    const {
      introParagraph,
      news,
      error,
    } = this.state;

    return (
      <Fragment>
        <HeroBanner
          image={bannerIllustration}
          mobileImg={bannerIllustrationMobile}
          text={introParagraph}
          bannerIcons
        />
        <News posts={news} />
        {renderDefaultErrorModal(error, this.closeModal)}
      </Fragment>
    );
  }

  render() {
    const { isLoading } = this.state;

    return isLoading ? <LoaderComponent /> : this.renderLoadedContent();
  }
}
