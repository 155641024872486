// @flow

import React from 'react';
import styled from 'styled-components';
import { MenuItems } from '../../constants/header';
import { NavMenu } from './menu';
import { ContentContainer } from '../content-container';
import { media } from '../../theme';

const HeaderContainer = styled.div`
  width: 100%;
  height: 64px;
  padding: ${p => p.theme.setSpacing(2)}px 0;
  border-bottom: 1px solid ${p => p.theme.colors.borderPrimary};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: ${p => p.theme.colors.backgroundPrimary};

  ${media.desktop`
    position: relative;
    height: ${p => p.theme.setSpacing(25)}px;
    padding: ${p => p.theme.setSpacing(8)}px 0 ${p => p.theme.setSpacing(4)}px;
  `}
`;

const HeaderInner = styled(ContentContainer)`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding: 0 ${p => p.theme.setSpacing(3)}px;

  ${media.desktop`
    padding: 0 ${p => p.theme.setSpacing(10)}px;
    `}
`;

export const Header = () => (
  <HeaderContainer>
    <HeaderInner>
      <NavMenu items={MenuItems} />
    </HeaderInner>
  </HeaderContainer>
);
