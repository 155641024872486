// @flow

type Props = {
  programName: string,
  city: string,
  zip: string,
  borough: string,
  facilityType: string,
};

export const createDirectoryQuery = (props: Props) => {
  const {
    programName,
    city,
    zip,
    borough,
    facilityType,
  } = props;

  return {
    'fields.accountName[match]': programName,
    'fields.zipCode[match]': zip,
    'fields.city[match]': city,
    'fields.borough[match]': borough,
    'fields.facilityType[match]': facilityType,
  };
};
