// @flow

import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { ModalComponent } from '../modal';

const photoSize = '288px';

const BioOuterWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  padding: ${p => p.theme.setSpacing(6)}px;
  background-color: ${p => p.theme.colors.backgroundPrimary};
`;

const BioContentWrapper = styled.div`
  width: auto;
  margin-left: ${p => p.theme.setSpacing(6)}px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Photo = styled.img`
  width: ${photoSize};
  height: ${photoSize};
  flex-shrink: 0;
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
`;

const BioWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const BioColumnsDiv = styled.div`
  width: 100%;
`;

// Min height and max height resolve browser compatibility across firefox & safari
const BioText = styled(ReactMarkdown)`
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
  column-gap: ${p => p.theme.setSpacing(5)}px;
  -webkit-column-gap: ${p => p.theme.setSpacing(5)}px;
  -moz-column-gap: ${p => p.theme.setSpacing(5)}px;
  column-fill: balance;
  height: auto;
  width: 100%;

  > p {
    min-height: 300px;
  }

  * {
    color: ${p => p.theme.colors.textPrimary};
    font-size: ${p => p.theme.fontSize.extraSmall}px;
    line-height: ${p => p.theme.lineHeight.medium};
    margin: 0;
    width: 100%;
  }

  a {
    &:hover {
      opacity: .8;
    }
  }
  ${p => p.theme.utils.resetLinkColor(p.theme.colors.textPrimary)};
`;

const NameWrapper = styled.div`
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.colors.borderPrimary};
  margin: 0 0 ${p => p.theme.setSpacing(2)}px;
`;

const Name = styled.h3`
  font-size: ${p => p.theme.fontSize.extraLarge}px;
  color: ${p => p.theme.colors.textSenary};
  font-weight: ${p => p.theme.fontWeight.semiBold};
  margin: 0 0 ${p => p.theme.setSpacing(1)}px;
`;

const Title = styled.p`
  font-size: ${p => p.theme.fontSize.small}px;
  color: ${p => p.theme.colors.textQuinary};
  font-weight: ${p => p.theme.fontWeight.normal};
  margin: 0 0 ${p => p.theme.setSpacing(2)}px;
`;

type Props = {
  photo: Object,
  title: string,
  name: string,
  bio: string,
  isModalOpen: boolean,
  closeModal: Function,
};

export const BioModal = (props: Props) => {
  const {
    photo,
    title,
    name,
    bio,
    closeModal,
    isModalOpen,
  } = props;

  const renderPhoto = () => {
    const {
      fields: {
        file: {
          url,
        },
      },
    } = photo;

    return (
      <Photo src={url} alt={name} />
    );
  };

  return (
    <ModalComponent
      isModalOpen={isModalOpen}
      closeModal={closeModal}
      isSecondary
    >
      <BioOuterWrapper>
        {photo && renderPhoto()}
        <BioContentWrapper>
          <NameWrapper>
            <Name>{name}</Name>
            <Title>{title}</Title>
          </NameWrapper>
          <BioWrapper>
            <BioColumnsDiv>
              <BioText linkTarget='_blank'>
                {bio}
              </BioText>
            </BioColumnsDiv>
          </BioWrapper>
        </BioContentWrapper>
      </BioOuterWrapper>
    </ModalComponent>
  );
};
