// @flow

export const TABS = ['Map', 'List'];

export const MAP_TAB = 'Map';

export const LIST_TAB = 'List';

export const BOROUGHS_ARRAY = [
  'Bronx',
  'Brooklyn',
  'Manhattan',
  'Queens',
  'Staten Island',
];

export const FACILITY_TYPES_ARRAY = [
  'Food Pantry',
  'Soup Kitchen',
  'Mobile Soup Kitchen',
  'Mobile Food Pantry',
];

export const FORM_INPUTS = [
  {
    label: 'First Name',
    id: 'firstName',
    required: true,
  },
  {
    label: 'Last Name',
    id: 'lastName',
    required: true,
  },
  {
    label: 'Email Address',
    id: 'email',
    required: true,
  },
  {
    label: 'Phone Number',
    id: 'phone',
    required: true,
  },
  {
    label: 'Extension',
    id: 'extension',
    required: false,
  },
  {
    label: 'Organization Name',
    id: 'organization',
    required: false,
  },
  {
    label: 'EFRO ID',
    id: 'efroid',
    required: false,
  },
  {
    label: 'Subject',
    id: 'subject',
    required: false,
  },
  {
    label: 'Message',
    id: 'message',
    type: 'textarea',
    required: true,
  },
];
