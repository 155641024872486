// @flow
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Download } from '../download';
import { media } from '../../theme';
import { ExternalLink } from '../external-link';
import { ContentContainer } from '../content-container';

const SlideContainer = styled.div`
  height: 480px;
  padding: 0;
  ${p => p.theme.utils.carouselColors(p.color)}

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(5)}px 0px;
    height: 380px;
  `}
`;

const SlideInnerWrapper = styled(ContentContainer)`
  display: flex;
  flex-wrap: wrap;
  padding-top: ${p => p.theme.setSpacing(3)}px;
  padding-bottom: ${p => p.theme.setSpacing(7)}px;
  height: 100%;
  overflow: visible;
  align-items: center;

  ${media.desktop`
    padding: 0 ${p => p.theme.setSpacing(15)}px;
    margin: 0 auto;
    flex-wrap: nowrap;
    justify-content: space-between;
  `}
`;

const SlideImage = styled.img`
  height: 130px;
  display: inline-block;
  object-fit: contain;
  margin: 0 auto ${p => p.theme.baseSpacing}px;

  ${media.desktop`
    height: 240px;
    margin: 0 ${p => p.theme.setSpacing(10)}px 0 0;
  `}
`;

const SlideTitle = styled.h2`
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.theme.fontSize.medium}px;
  font-weight: ${p => p.theme.fontWeight.thin};
  line-height: ${p => p.theme.lineHeight.extraSmall};
  color: ${p => p.theme.colors.textSecondary};
  margin: 0 0 ${p => p.theme.setSpacing(2)}px;
  text-align: center;
  display: block;
  width: 100%;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.extraLarge}px;
    margin: 0 0 ${p => p.theme.setSpacing(3)}px;
    text-align: left;
  `}
`;

const SlideContentBlock = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  ${media.desktop`
    max-width: 800px;
    justify-content: space-between;
  `}
`;

const SlideTextBlock = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

const SlideDescription = styled(ReactMarkdown)`
  display: block;
  width: 100%;
  * {
    font-family: ${p => p.theme.fontFamily};
    font-size: ${p => p.theme.fontSize.extraSmall}px;
    color: ${p => p.theme.colors.textSecondary};
    line-height: ${p => p.theme.lineHeight.small};
    font-weight: ${p => p.theme.fontWeight.thin};
    margin-top: 0;
    margin-bottom: ${p => p.theme.setSpacing(4)}px;
    text-align: center;

    ${media.desktop`
      font-size: ${p => p.theme.fontSize.medium}px;
      line-height: ${p => p.theme.lineHeight.small};
      text-align: left;
      margin-bottom: ${p => p.theme.setSpacing(5)}px;

      a {
        &:hover {
          opacity: .8;
        }
      }
    `}
  }
  ${p => p.theme.utils.resetLinkColor(p.theme.colors.textSecondary)};
`;

const renderTitle = (title: string) => (
  <SlideTitle>
    {title}
  </SlideTitle>
);

const renderDescription = (description: string) => (
  <SlideDescription linkTarget='_blank'>
    {description}
  </SlideDescription>
);

const renderImage = (img) => {
  const validImage = img
    && img.fields
    && img.fields.file
    && img.fields.file.url;
  return (
    <SlideImage
      src={validImage}
      alt={validImage}
    />
  );
};

type Props = {
  title: string,
  description?: string,
  resources?: Object,
  externalUrl?: string,
  externalUrlTitle?: string,
  image?: Object,
  color?: string,
}

export const Slide = (props: Props) => {
  const {
    title,
    description,
    resources,
    externalUrl,
    externalUrlTitle,
    image,
    color,
  } = props;

  const linkButton = externalUrl
    && externalUrlTitle
    && (
    <ExternalLink
      url={externalUrl}
      text={externalUrlTitle}
      isTertiary
    />
    );

  const resourceDocuments = resources
    && resources.fields
    && resources.fields.documents;

  return (
    <SlideContainer color={color}>
      <SlideInnerWrapper>
        {image && renderImage(image)}
        <SlideContentBlock>
          <SlideTextBlock>
            {renderTitle(title)}
            {description
              && renderDescription(description)}
          </SlideTextBlock>
          {resourceDocuments
            && <Download isTertiary pdfs={resourceDocuments} />}
          {linkButton}
        </SlideContentBlock>
      </SlideInnerWrapper>
    </SlideContainer>
  );
};

Slide.defaultProps = {
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque mattis pellentesque',
  resources: [],
  externalUrl: '',
  externalUrlTitle: '',
  image: {},
  color: 'dark green',
};
