// @flow
import cityHarvestIcon from '../assets/images/organizations/city-harvest.png';
import fbnycIcon from '../assets/images/organizations/fbnyc.png';
import uwnycIcon from '../assets/images/organizations/uwnyc.png';
import nycHealthIcon from '../assets/images/organizations/nyc-health.png';
import nycHRAIcon from '../assets/images/organizations/nyc-hra.png';
import hpnapIcon from '../assets/images/organizations/hpnap.png';


export const ORGANIZATIONS = [
  {
    url: 'http://www.cityharvest.org/',
    image: cityHarvestIcon,
    alt: 'City Harvest',
  },
  {
    url: 'http://www.foodbanknyc.org/',
    image: fbnycIcon,
    alt: 'Food Bank of NYC',
  },
  {
    url: 'http://www.unitedwaynyc.org/',
    image: uwnycIcon,
    alt: 'United Way of NYC',
  },
  {
    url: 'https://www1.nyc.gov/site/doh/index.page',
    image: nycHealthIcon,
    alt: 'NYC Department of Health',
  },
  {
    url: 'https://www1.nyc.gov/site/hra/index.page',
    image: nycHRAIcon,
    alt: 'NYC Human Resources Administration',
  },
  {
    url: 'https://www.health.ny.gov/prevention/nutrition/hpnap/index.htm',
    image: hpnapIcon,
    alt: 'HPNAP',
  },
];
