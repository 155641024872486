// @flow
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { media } from '../theme';
import { Organizations } from './organizations';

const Banner = styled.div`
  margin-top: ${p => p.theme.setSpacing(5)}px;
  padding-top: ${p => p.theme.setSpacing(6)}px;
  position: relative;

  height: ${p => (
    // eslint-disable-next-line
    p.bannerIcons
      ? `${p.theme.setSpacing(65)}px`
      : p.image
        ? `${p.theme.setSpacing(58)}px`
        : 'auto'
  )}

  ${media.desktop`
    padding-top: ${p => (p.image ? p.theme.setSpacing(3) : 0)}px;

    height: ${p => (
    p.image
      ? `${p.theme.setSpacing(79)}px`
      : 'auto'
  )}

  `}
`;

const BannerImgDiv = styled.div`
  ${p => p.theme.utils.bgImgCover}
  background-position: center bottom;
  background-size: contain;
  background-image: url(${p => p.mobileImg});
  height: ${p => p.theme.setSpacing(22)}px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  ${media.tablet`
    background-image: url(${p => p.mobileImg});
    height: ${p => p.theme.setSpacing(40)}px;
  `}

  ${media.desktop`
    background-image: url(${p => p.image});
    height: ${p => p.theme.setSpacing(69)}px;
  `};
`;

const BannerTextWrapper = styled.div`
  width: 75%;
  margin: 0 auto;

  ${media.desktop`
    width: ${p => p.theme.setSpacing(73)}px;
  `}
`;

const BannerText = styled(ReactMarkdown)`
  * {
    font-size: ${p => p.theme.fontSize.extraSmall}px;
    font-weight: ${p => p.theme.fontWeight.thin};
    text-align: center;
    line-height: ${p => p.theme.lineHeight.medium};
    margin-bottom: ${p => p.theme.setSpacing(3)}px;
    color: ${p => p.theme.colors.textPrimary};

    ${media.desktop`
      font-size: ${p => p.theme.fontSize.medium}px;
      margin-bottom: ${p => p.theme.setSpacing(5)}px;
      a {
        &:hover {
          opacity: .8;
        }
      }
    `}
  }

  ${p => p.theme.utils.resetLinkColor(p.theme.colors.textPrimary)};
`;

const BannerTitle = styled.h1`
  font-size: ${p => p.theme.fontSize.large}px;
  font-weight: ${p => p.theme.fontWeight.bold};
  color: ${p => p.theme.colors.textTertiary};
  text-align: center;
  margin: ${p => p.theme.setSpacing(2)}px 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.huge}px;
  `}
`;

type Props = {
  image?: string,
  mobileImg?: string,
  text?: string,
  title?: string,
  bannerIcons?: boolean,
}

export const HeroBanner = (props: Props) => {
  const {
    image,
    mobileImg,
    text,
    title,
    bannerIcons,
  } = props;
  return (
    <Banner image={image && mobileImg} bannerIcons={bannerIcons}>
      <BannerTextWrapper>
        { title
          && <BannerTitle>{title}</BannerTitle>
        }
        <BannerText linkTarget='_blank' source={text} />
        { bannerIcons
          && (
            <Organizations />
          )
        }
      </BannerTextWrapper>
      { (image && mobileImg)
        && (
          <BannerImgDiv
            image={image}
            mobileImg={mobileImg}
          />
        )
      }
    </Banner>
  );
};

HeroBanner.defaultProps = {
  image: '',
  mobileImg: '',
  title: '',
  text: '',
  bannerIcons: false,
};
