// @flow
import * as contentful from 'contentful';
import axios from 'axios';
import {
  AWS_API_URL,
  CONTACT_FORM_HEADERS,
} from '../constants/api';

const client = contentful.createClient({
  space: process.env.REACT_APP_SPACE_ID,
  accessToken: process.env.REACT_APP_API_KEY,
});

export const fetchHome = () => client.getEntries({
  content_type: 'homePage',
  include: 4,
});

export const fetchFooter = () => client.getEntries({
  content_type: 'footer',
});

export const fetchAboutPage = () => client.getEntries({
  content_type: 'aboutUsPage',
  include: 3,
});

export const fetchResourcesPage = () => client.getEntries({
  content_type: 'resourcesPage',
  include: 3,
});

export const fetchContactUsPage = () => client.getEntries({
  content_type: 'contactUsPage',
});

export const fetchEFPIntro = () => client.getEntries({
  content_type: 'efpIntro',
});

export const searchEFPDirectory = (queryObject: Object) => client.getEntries({
  content_type: 'efpDirectory',
  ...queryObject,
});

export const postContactForm = (formData: Object) => {
  axios.request({
    url: AWS_API_URL,
    method: 'post',
    data: formData,
    headers: CONTACT_FORM_HEADERS,
  });
};
