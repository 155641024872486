// @flow

import React, { Fragment, PureComponent } from 'react';
import objectFitImages from 'object-fit-images';
import { fetchAboutPage } from '../../services/api';
import { renderDefaultErrorModal } from '../error-modal';
import { AboutIntro } from './intro';
import { Commitments } from './commitments';
import { OurResources } from './our-resources';
import { Committee } from './committee';
import { FAQs } from './faq';
import { HeroBanner } from '../hero-banner';
import { LoaderComponent } from '../loader';
import bannerIllustration from '../../assets/images/gifs/about_banner.gif';
import bannerIllustrationMobile from '../../assets/images/gifs/about_mobile_banner.gif';

type State = {
  introParagraph: string,
  hungerStatistics: Object,
  commitments: Array<Object>,
  featuredResources: Array<Object>,
  committeeMembers: Array<Object>,
  faqs: Array<Object>,
  error: boolean,
  isLoading: boolean,
}

type Props = {
  hash: string,
}

export class AboutContent extends PureComponent<Props, State> {
  state = {
    introParagraph: '',
    hungerStatistics: {},
    commitments: [],
    committeeMembers: [],
    featuredResources: [],
    faqs: [],
    error: false,
    isLoading: true,
  }

  componentDidMount() {
    const { hash } = this.props;
    objectFitImages();
    fetchAboutPage()
      .then(this.setPageData)
      .then(() => {
        if (hash === '#faqs') {
          // $FlowFixMe
          window.scrollTo(0, this.faqs.offsetTop);
        }
      })
      .catch(this.catchError); // Error modal
  }

  catchError = () => this.setState(() => ({ error: true, isLoading: false }))

  closeModal = () => this.setState(() => ({ error: false }));

  setPageData = (response: Object) => {
    const {
      fields:
      {
        introParagraph,
        hungerStatistics,
        commitments,
        featuredResources,
        committeeMembers,
        faqs,
      },
    } = response.items[0];

    this.setState(() => ({
      introParagraph,
      hungerStatistics,
      commitments,
      featuredResources,
      committeeMembers,
      faqs,
      error: false,
      isLoading: false,
    }));
  }

  renderIntroParagraph = (introParagraph: string) => (
    <HeroBanner
      image={bannerIllustration}
      mobileImg={bannerIllustrationMobile}
      text={introParagraph || ''}
      title='About Us'
    />
  );

  renderLoadedContent = () => {
    const {
      introParagraph,
      hungerStatistics,
      commitments,
      featuredResources,
      committeeMembers,
      faqs,
      error,
    } = this.state;

    return (
      <Fragment>
        {introParagraph && this.renderIntroParagraph(introParagraph)}
        { (hungerStatistics && hungerStatistics.fields)
          && (
            <AboutIntro
              hungerStatistics={hungerStatistics}
            />
          )
        }
        {(commitments && (commitments.length > 0))
          && (
          <Commitments
            commitments={commitments}
          />
          )
        }
        {featuredResources && <OurResources featuredResources={featuredResources} />}
        {committeeMembers && <Committee committeeMembers={committeeMembers} />}
        {faqs
          && (
            <div
              ref={(element) => {
                // $FlowFixMe
                this.faqs = element;
                return this.faqs;
              }}
            >
              <FAQs
                faqs={faqs}
              />
            </div>
          )}
        {renderDefaultErrorModal(error, this.closeModal)}
      </Fragment>
    );
  }

  render() {
    const { isLoading } = this.state;

    return isLoading ? <LoaderComponent /> : this.renderLoadedContent();
  }
}
