// @flow

import React from 'react';
import styled, { keyframes } from 'styled-components';

import loadingImage from '../assets/images/loader.png';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoaderDiv = styled.div`
  height: ${p => (p.isContainerLoader ? '100%' : '60vh')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LoaderImg = styled.img`
  width: 60px;
  animation: ${rotation} 2s linear infinite;
`;

type Props = {
  isContainerLoader?: boolean,
}

export const LoaderComponent = (props: Props) => (
  <LoaderDiv {...props}>
    <LoaderImg
      src={loadingImage}
      alt='Loading...'
    />
  </LoaderDiv>
);

LoaderComponent.defaultProps = {
  isContainerLoader: false,
};
