// @flow
import React from 'react';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { ContentContainer } from '../content-container';
import { media } from '../../theme';
import { Question } from './question';

const FAQContainer = styled.div`
  background-color: ${p => p.theme.colors.backgroundSecondary};
  padding: ${p => p.theme.setSpacing(6)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(12)}px 0;
  `}
`;

const FAQHeading = styled.h1`
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.theme.fontSize.large}px;
  font-weight: ${p => p.theme.fontWeight.semiBold};
  color: ${p => p.theme.colors.textSecondary};
  margin-top: 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.huge}px;
  `}
`;

type Props = {
  faqs: Array<Object>,
}

export const FAQs = (props: Props) => {
  const { faqs } = props;

  return (
    <FAQContainer id='faqs'>
      <ContentContainer>
        <FAQHeading>FAQ</FAQHeading>
        {faqs.map((q) => {
          const { fields: { question, answer } } = q;
          return (
            <Question key={uniqid()} question={question} response={answer} />
          );
        })}
      </ContentContainer>
    </FAQContainer>
  );
};
