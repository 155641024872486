// @flow

import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { media } from '../../theme';
import { ContentContainer } from '../content-container';
import foodIcon from '../../assets/images/resource-icons/food.png';
import capacityBuildingIcon from '../../assets/images/resource-icons/capacity-building.png';
import trainingEducationIcon from '../../assets/images/resource-icons/training-education.png';

const ICON_ONE = 'food';
const ICON_TWO = 'capacity-building';
const ICON_THREE = 'training-education';

const ResourcesContainer = styled.div`
  background-color: ${p => p.theme.colors.backgroundTertiary};
  padding: ${p => p.theme.setSpacing(6)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(8)}px 0;
  `}
`;

const FlexedOuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${p => p.theme.setSpacing(4)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(6)}px 0 0;
    flex-wrap: nowrap;
  `}
`;

const ResourcesSectionContainer = styled.div`
  ${media.desktop`
    width: 31%;
  `}
`;

const ResourceTitle = styled.h2`
  color: ${p => p.theme.colors.textSecondary};
  font-size: ${p => p.theme.fontSize.medium}px;
  font-weight: ${p => p.theme.fontWeight.semiBold};
  align-self: center;
  margin: 0;
  text-align: center;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.large}px;
    `}
`;

const ResourceIcon = styled.div`
  background-image: url(${p => p.image});
  flex-shrink: 0;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  width: 102px;
  height: 123px;
  margin: 0 auto ${p => p.theme.setSpacing(3)}px;
`;

const ResourceTitleFlexWrapper = styled.div`
  padding: 0;
  display: flex;
  justify-content: center;
`;

const ResourceHeading = styled.h1`
  font-size: ${p => p.theme.fontSize.large}px;
  font-weight: ${p => p.theme.fontWeight.bold};
  color: ${p => p.theme.colors.textSecondary};
  text-align: center;
  margin: 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.huge}px;
    margin: ${p => p.theme.setSpacing(2)}px 0;
  `}
`;

const SectionDescription = styled(ReactMarkdown)`
  * {
    font-family: ${p => p.theme.fontFamily};
    font-size: ${p => p.theme.fontSize.extraSmall}px;
    color: ${p => p.theme.colors.textSecondary};
    line-height: ${p => p.theme.lineHeight.large};
    font-weight: ${p => p.theme.fontWeight.normal};
    margin: ${p => p.theme.setSpacing(4)}px 0;
    padding: 0;
    text-align: center;

    ${media.desktop`
      padding: 0 ${p => p.theme.setSpacing(3)}px;

      a {
        &:hover {
          opacity: .8;
        }
      }
    `}
  }
  ${p => p.theme.utils.resetLinkColor(p.theme.colors.textSecondary)};
`;

const renderIcon = (icon: string) => {
  switch (icon) {
    case ICON_ONE:
      return foodIcon;
    case ICON_TWO:
      return capacityBuildingIcon;
    case ICON_THREE:
      return trainingEducationIcon;
    default:
      return capacityBuildingIcon;
  }
};

type Props = {
  featuredResources: Array<Object>,
}

export const OurResources = (props: Props) => {
  const { featuredResources } = props;

  const orderedResources = featuredResources && featuredResources.sort((a, b) => {
    if (a.fields && a.fields.order && b.fields && b.fields.order) {
      return (a.fields.order > b.fields.order) ? 1 : -1;
    }
    return 0;
  });

  return (
    <ResourcesContainer>
      <ContentContainer>
        <ResourceHeading>Our Resources</ResourceHeading>
        <FlexedOuterContainer>
          { (orderedResources && orderedResources.length === 3)
            && orderedResources.map((resource: Object) => {
              const {
                fields: {
                  title,
                  description,
                  icon,
                },
              } = resource;

              return (
                <ResourcesSectionContainer key={title}>
                  <ResourceIcon image={renderIcon(icon)} />
                  <ResourceTitleFlexWrapper>
                    <ResourceTitle>
                      {title}
                    </ResourceTitle>
                  </ResourceTitleFlexWrapper>
                  <SectionDescription linkTarget='_blank'>
                    {description}
                  </SectionDescription>
                </ResourcesSectionContainer>
              );
            })
          }
        </FlexedOuterContainer>
      </ContentContainer>
    </ResourcesContainer>
  );
};
