// @flow

import React from 'react';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { ERROR_MESSAGE } from '../constants/error';
import { ModalComponent } from './modal';
import { media } from '../theme';

const ErrorContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

const ModalInner = styled.div`
  background-color: ${p => p.theme.colors.backgroundQuaternary};
  width: 300px;
  height: 242px;
  position: relative;

  ${media.desktop`
    width: 600px;
  `}
`;

const ErrorText = styled.h2`
  color: ${p => p.theme.colors.textSecondary};
  font-size: ${p => p.theme.fontSize.small}px;
  font-weight: ${p => p.theme.fontWeight.regular};
  line-height: ${p => p.theme.lineHeight.small};
  text-align: center;
  display: block;
  width: 100%;
  margin: 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.large}px;
  `}
`;

const ErrorWrapper = styled.div`
  display: block;
`;

type Props = {
  message?: Array<string>,
  error: boolean,
  closeModal: Function,
};

export const ErrorModal = (props: Props) => {
  const { message, error, closeModal } = props;

  const renderedMessage = message || ERROR_MESSAGE;

  return (
    <ModalComponent isModalOpen={error} closeModal={closeModal}>
      <ModalInner>
        <ErrorContent>
          <ErrorWrapper>
            {renderedMessage.map(text => <ErrorText key={uniqid()}>{text}</ErrorText>)}
          </ErrorWrapper>
        </ErrorContent>
      </ModalInner>
    </ModalComponent>
  );
};

ErrorModal.defaultProps = {
  message: ERROR_MESSAGE,
};

export const renderDefaultErrorModal = (
  error: boolean,
  closeModal: Function,
) => (
  !error ? null : (
    <ErrorModal error={error} closeModal={closeModal} />
  )
);
