// @flow

import React from 'react';
import styled from 'styled-components';
import { media } from '../theme';
import { ORGANIZATIONS } from '../constants/organizations';
import { handleOrganizationClick } from '../utils/analytics';

const iconSize = '64px';

const OrganizationsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  justify-content: space-around;

  ${media.tablet`
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
  `}
`;

const OrganizationIcon = styled.img`
  width: ${iconSize};
  height: ${iconSize};
  display: inline-block;
  object-fit: contain;
  margin: ${p => p.theme.setSpacing(1 / 2)}px;
`;

const renderOrganizations = () => ORGANIZATIONS.map((org: Object) => {
  const { url, alt, image } = org;

  return (
    <a
      href={url}
      alt={alt}
      key={alt}
      target='_blank'
      rel='noopener noreferrer'
      onClick={() => handleOrganizationClick(alt)}
    >
      <OrganizationIcon
        src={image}
        alt={alt}
      />
    </a>
  );
});

export const Organizations = () => (
  <OrganizationsWrapper>
    {renderOrganizations()}
  </OrganizationsWrapper>
);
