// @flow

import React from 'react';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { media } from '../../theme';
import { ContentContainer } from '../content-container';
import { MemberCard } from './member-card';

const CommitteeContainer = styled.div`
  background-color: ${p => p.theme.colors.backgroundPrimary};
  padding: ${p => p.theme.setSpacing(6)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(8)}px 0;
  `}
`;

const FlexedOuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: ${p => p.theme.setSpacing(4)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(6)}px 0 0;
  `}
`;

const CommitteeHeading = styled.h1`
  font-size: ${p => p.theme.fontSize.large}px;
  font-weight: ${p => p.theme.fontWeight.bold};
  color: ${p => p.theme.colors.textTertiary};
  text-align: center;
  margin: ${p => p.theme.setSpacing(2)}px 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.huge}px;
  `}
`;

type Props = {
  committeeMembers: Array<Object>,
}

export const Committee = (props: Props) => {
  const { committeeMembers } = props;

  return (
    <CommitteeContainer>
      <ContentContainer>
        <CommitteeHeading>Policy Committee Representatives</CommitteeHeading>
        <FlexedOuterContainer>
          { committeeMembers
            && committeeMembers.map((member: Object) => (
              <MemberCard key={uniqid()} {...member.fields} />
            ))
          }
        </FlexedOuterContainer>
      </ContentContainer>
    </CommitteeContainer>
  );
};
