// @flow

import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { Button } from '../button';
import { ErrorModal } from '../error-modal';
import { ThankYouModal } from './thank-you-modal';
import { FormInput } from '../input';
import { FORM_INPUTS } from '../../constants/efp-form';
import { media } from '../../theme';
import { postContactForm } from '../../services/api';

const FormWrapper = styled.div`
  padding: 0 ${p => p.theme.setSpacing(4)}px;
  max-width: 950px;
  margin: 0 auto;

  ${media.desktop`
    padding: 0 ${p => p.theme.setSpacing(8)}px ${p => p.theme.setSpacing(4)}px;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: ${p => p.theme.setSpacing(6)}px auto;
  justify-content: center;

  ${media.desktop`
    width: 75%;
  `}
`;

const SendButton = styled(Button)`
  justify-content: center;
`;

type State = {
  error: boolean,
  errorMessage: Array<string>,
  submitted: boolean,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  extension: string,
  organization: string,
  efroid: string,
  subject: string,
  message: string,
}

const INITIAL_STATE = {
  error: false,
  errorMessage: [],
  submitted: false,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  extension: '',
  organization: '',
  efroid: '',
  subject: '',
  message: '',
};

export class ContactForm extends PureComponent<{}, State> {
  state = INITIAL_STATE;

  handleChange = (e: any, property: string) => {
    const { target: { value } } = e;
    this.setState(() => ({ [property]: value }));
  }

  handleResetForm = () => this.setState(() => ({ ...INITIAL_STATE }));

  closeModal = () => this.setState(() => ({
    error: false,
    errorMessage: [],
    submitted: false,
  }));

  renderErrorModal = () => {
    const { error, errorMessage } = this.state;

    return !error ? null : (
      <ErrorModal
        error={error}
        message={errorMessage}
        closeModal={this.closeModal}
      />
    );
  }

  renderThankYouModal = () => {
    const { submitted } = this.state;

    return !submitted ? null : (
      <ThankYouModal
        submitted={submitted}
        closeModal={this.handleResetForm}
      />
    );
  }

  catchError = (message?: Array<string>) => this.setState(() => ({
    error: true,
    errorMessage: message || [],
  }))

  createErrorMessage = (missingValues: Array<Object>) => (
    missingValues.map<Object>((value: Object) => {
      const { label } = value;
      return `Please Enter ${label}`;
    })
  )

  handleFormSubmit = () => {
    const requiredInputs = FORM_INPUTS.filter(item => item.required === true);
    // eslint-disable-next-line
    const missingInputs = requiredInputs.filter(input => (this.state[input.id] === ''));

    if (missingInputs.length > 0) {
      this.catchError(this.createErrorMessage(missingInputs));
    } else {
      postContactForm(this.state);
      this.setState(() => ({ submitted: true }));
    }
  }

  renderInputs = () => FORM_INPUTS.map<Object>(option => (
      // eslint-disable-next-line
      <FormInput
        key={option.id}
        value={
          // eslint-disable-next-line
          this.state[option.id]
        }
        {...option}
        onChange={e => this.handleChange(e, option.id)}
      />
  ));

  render() {
    return (
      <Fragment>
        <FormWrapper>
          {this.renderInputs()}
          <ButtonsWrapper>
            <SendButton
              onClick={this.handleFormSubmit}
            >
              Send Message
            </SendButton>
          </ButtonsWrapper>
        </FormWrapper>
        {this.renderErrorModal()}
        {this.renderThankYouModal()}
      </Fragment>
    );
  }
}
