// @flow
import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../theme';

export const buttonPadding = css`
  ${p => p.theme.setSpacing(1.5)}px ${p => p.theme.setSpacing(2)}px;
`;

export const buttonWidth = css`
  ${p => p.theme.setSpacing(35)}px
`;

export const buttonWidthSmall = css`
  ${p => p.theme.setSpacing(20)}px
`;

const Primary = styled.a`
  color: ${p => p.theme.colors.buttonPrimaryText};
  background-color: ${p => p.theme.colors.buttonPrimaryColor};
  border: none;
  padding: ${buttonPadding};
  font-size: ${p => p.theme.fontSize.small}px;
  font-family: ${p => p.theme.fontFamily};
  font-weight: ${p => p.theme.fontWeight.thin};
  line-height: ${p => p.theme.lineHeight.extraSmall};
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${media.desktop`
    width: ${p => (p.isSmall ? buttonWidthSmall : buttonWidth)}
    font-size: ${p => p.theme.fontSize.small}px;

    &:hover {
      background-color: ${p => p.theme.colors.buttonPrimaryHover}
    }
  `}
`;

const Inverted = styled(Primary)`
  color: ${p => p.theme.colors.buttonPrimaryColor};
  background-color: ${p => p.theme.colors.buttonPrimaryText};
  border: none;

  ${media.desktop`
    &:hover {
      background-color: ${p => p.theme.colors.buttonPrimaryText};
      opacity: .9;
    }
  `}
`;

const Icon = styled.img`
  width: ${p => p.theme.setSpacing(2)}px;
  transition: all .2s linear;

  ${p => (p.menuOpen ? 'transform: rotate(180deg);' : '')}
  ${media.desktop`
    width: ${p => p.theme.setSpacing(2.5)}px;
  `}
`;

type Props = {
  isInverted?: boolean,
  children?: any,
  icon?: string,
  isSmall?: boolean,
  menuOpen?: boolean,
};

export const Button = (props: Props) => {
  const {
    isInverted,
    children,
    icon,
    menuOpen,
  } = props;

  const renderedIcon = icon && <Icon menuOpen={menuOpen} alt='icon' src={icon} />;

  const renderedButton = isInverted
    ? (
      <Inverted {...props}>
        {children}
        {renderedIcon}
      </Inverted>
    ) : (
      <Primary {...props}>
        {children}
        {renderedIcon}
      </Primary>
    );

  return renderedButton;
};

Button.defaultProps = {
  isInverted: false,
  children: null,
  icon: '',
  isSmall: false,
};
