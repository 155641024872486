// @flow

import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { media } from '../theme';
import { ContentContainer } from './content-container';
import Logo from '../assets/images/footer-logo.svg';
import GroceryBag from '../assets/images/grocery-bag.png';
import LogoMobile from '../assets/images/feed-logo-white.png';
import { fetchFooter } from '../services/api';
import { handleFooterClick } from '../utils/analytics';

const FooterWrapper = styled.div`
  height: ${p => p.theme.setSpacing(36)}px;
  background-color: ${p => p.theme.colors.backgroundSenary};
  display: flex;
  align-items: center;
  width: 100%;

  ${media.tablet`
    height: ${p => p.theme.setSpacing(18)}px;
    background-color: ${p => p.theme.colors.backgroundPrimary};
  `}
`;

const FooterInner = styled(ContentContainer)`
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  height: 100%;

  ${media.tablet`
    flex-wrap: nowrap;
    width: 100%;
  `}
`;

const FooterLink = styled.a`
  color: ${p => p.theme.colors.textSecondary};
  text-decoration: none;
  font-size: ${p => p.theme.fontSize.small}px;
  font-weight: ${p => p.theme.fontWeight.thin};
  margin: 0;
  display: block;
  width: 100%;

  &:last-of-type {
    margin: 0;
  }

  ${media.tablet`
    color: ${p => p.theme.colors.textPrimary};
    margin: 0 0 0 ${p => p.theme.setSpacing(3)}px;
    display: inline-block;
    width: auto;

    &:last-of-type {
      margin: 0 0 0 ${p => p.theme.setSpacing(3)}px;
    }
  `};

   ${media.desktop`
     &:hover {
       color: ${p => p.theme.colors.textQuinary};
     }
  `};
`;

const CopyrightSpan = styled.div`
  color: ${p => p.theme.colors.textSecondary};
  text-decoration: none;
  font-size: ${p => p.theme.fontSize.small}px;
  font-weight: ${p => p.theme.fontWeight.thin};
  margin: 0;
  display: block;
  width: 100%;
  cursor: default;

  &:last-of-type {
    margin: 0;
  }

  ${media.tablet`
    color: ${p => p.theme.colors.textPrimary};
    margin: 0 0 0 ${p => p.theme.setSpacing(3)}px;
    display: inline-block;
    width: auto;

    &:last-of-type {
      margin: 0 0 0 ${p => p.theme.setSpacing(3)}px;
    }
  `};
`;

const ImgWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-wrap: wrap;
  order: 1;
  width: 40%;

  ${media.tablet`
    flex-wrap: nowrap;
    order: 0;
    width: auto;
  `}
`;

const LinksWrapper = styled(ImgWrapper)`
  justify-content: flex-end;
  order: 0;
  width: 60%;
  padding: 24px 0;

  ${media.tablet`
    order: 1;
    padding: 0;
  `}
`;

const FooterLogo = styled.img`
  display: none;

  ${media.tablet`
    display: inline-block;
    width: 180px;
    height: 33px;
  `}
`;

const FooterLogoMobile = styled.img`
  display: block;
  width: 163px;
  height: 31px;
  margin-right: auto;

  ${media.tablet`
    display: none;
  `}
`;

const FooterMobileImg = styled.img`
  display: inline-block;
  width: 97px;
  height: 167px;

  ${media.tablet`
    display: none;
  `}
`;

type State = {
  links: Array<Object>,
}

export class Footer extends PureComponent<{}, State> {
  state = {
    links: [],
  }

  componentDidMount() {
    fetchFooter()
      .then((response) => {
        const { fields } = response.items[0];
        this.setFooter(fields.links);
      })
      .catch(console.error); // eslint-disable-line
  }

  setFooter = (links: Array<Object>) => this.setState(() => ({
    links,
  }));

  renderLoadedContent = () => {
    const { links } = this.state;
    return (
      <FooterWrapper>
        <FooterInner>
          <ImgWrapper>
            <FooterLogo src={Logo} alt='logo' />
            <FooterMobileImg src={GroceryBag} alt='grocery-bag' />
          </ImgWrapper>
          <LinksWrapper>
            <FooterLogoMobile src={LogoMobile} alt='logo' />
            {
              links.map((item: Object) => {
                const { fields: { text, link } } = item;

                return (
                  <FooterLink
                    key={text}
                    href={link}
                    target='_blank'
                    onClick={() => handleFooterClick(text)}
                  >
                    {text}
                  </FooterLink>
                );
              })
            }
            <CopyrightSpan>
              &#169; Copyright
              {' '}
              {new Date().getFullYear()}
            </CopyrightSpan>
          </LinksWrapper>
        </FooterInner>
      </FooterWrapper>
    );
  }

  render() {
    return this.renderLoadedContent();
  }
}
