// @flow

import React from 'react';
import styled from 'styled-components';
import { media } from '../theme';

const InputLabelFlex = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: ${p => p.theme.setSpacing(3)}px 0;

  ${media.desktop`
    flex-wrap: nowrap;
  `}
`;

const FormLabel = styled.label`
  font-size: ${p => p.theme.fontSize.extraSmall}px;
  font-weight: ${p => p.theme.fontWeight.thin};
  color: ${p => p.theme.colors.textPrimary};
  text-align: left;
  width: 100%;
  margin-bottom: ${p => p.theme.baseSpacing}px;

  ${media.desktop`
    width: 20%;
    text-align: right;
    margin-bottom: 0;
    font-size: ${p => p.theme.fontSize.small}px;
  `}

  ${p => (p.required
    ? `
      :before {
        content: '*';
        color: ${p.theme.colors.textQuinary};
      }
    `
    : ''
  )}
`;

const PrimaryInput = styled.input`
  display: inline-block;
  width: 100%;
  padding: 5px 10px;
  border: 1px solid ${p => p.theme.colors.borderPrimary};

  ${media.desktop`
    width: 65%;
    margin-left: ${p => p.theme.setSpacing(3)}px;
  `}
`;

const PrimaryTextArea = styled.textarea`
  display: inline-block;
  border: 1px solid ${p => p.theme.colors.borderPrimary};
  height: 150px;
  width: 100%;
  &:required {
    box-shadow: none;
  }

  ${media.desktop`
    width: 65%;
    margin-left: ${p => p.theme.setSpacing(3)}px;
  `}
`;

type Props = {
  onChange: Function,
  id: string,
  value: string,
  label: string,
  type?: string,
  required?: boolean,
}

export const FormInput = (props: Props) => {
  const {
    id,
    label,
    type,
    required,
  } = props;
  return (
    <InputLabelFlex>
      <FormLabel htmlFor={id} required={required}>
        {label}
      </FormLabel>
      { type === 'textarea'
        ? <PrimaryTextArea {...props} />
        : <PrimaryInput {...props} />}
    </InputLabelFlex>
  );
};

FormInput.defaultProps = {
  type: 'text',
  required: false,
};
