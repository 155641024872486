// @flow
import {
  ABOUT_ROUTE,
  EFP_DIR_ROUTE,
  RESOURCES_ROUTE,
  FAQS_ROUTE,
  CONTACT_ROUTE,
} from "./routes";

export const MenuItems = [
  {
    text: "About",
    url: ABOUT_ROUTE,
  },
  {
    text: "Resources & Grants",
    url: RESOURCES_ROUTE,
  },
  {
    text: "FAQ",
    hash: "faqs",
    url: FAQS_ROUTE,
  },
  {
    text: "EFP Directory",
    url: EFP_DIR_ROUTE,
  },
  {
    text: "Contact Us",
    url: CONTACT_ROUTE,
  },
];

export const LOGIN_URL =
  "https://feednyc.powerappsportals.com/SignIn?returnUrl=%2F";
