// @flow

import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { media } from '../../theme';
import { ContentContainer } from '../content-container';
import awardIcon from '../../assets/images/award-icon.png';
import towerIcon from '../../assets/images/tower-icon.png';

const CommitmentsContainer = styled.div`
  background-color: ${p => p.theme.colors.backgroundPrimary};
  padding: ${p => p.theme.setSpacing(4)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(8)}px 0;
  `}
`;

const FlexedOuterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${p => p.theme.setSpacing(2)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(6)}px 0 0;
    flex-wrap: nowrap;
  `}
`;

const CommitmentSectionContainer = styled.div`
  &:first-of-type {
    margin-bottom: ${p => p.theme.setSpacing(5)}px;
  }
  ${media.desktop`
    width: 45%;
    &:first-of-type {
      margin-bottom: 0;
    }
  `}
`;

const CommitmentTitle = styled.h2`
  color: ${p => p.theme.colors.textSenary};
  font-size: ${p => p.theme.fontSize.medium}px;
  align-self: center;
  margin-bottom: 0;
  text-align: center;
  margin: 0;
  width: 100%;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.large}px;
    text-align: left;
  `}
`;

const CommitmentIcon = styled.div`
  background-image: url(${p => p.image});
  flex-shrink: 0;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  width: 102px;
  height: 123px;
  margin-bottom: ${p => p.theme.setSpacing(4)}px;

  ${media.desktop`
    margin-right: ${p => p.theme.setSpacing(4)}px;
    margin-bottom: 0;
  `}
`;

const CommitmentTitleFlexWrapper = styled.div`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${media.desktop`
    padding: 0 0 ${p => p.theme.setSpacing(4)}px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    border-bottom: 1px solid ${p => p.theme.colors.borderQuinary};
  `}
`;

const CommitmentHeading = styled.h1`
  font-size: ${p => p.theme.fontSize.large}px;
  font-weight: ${p => p.theme.fontWeight.bold};
  color: ${p => p.theme.colors.textTertiary};
  text-align: center;
  margin: ${p => p.theme.setSpacing(2)}px 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.huge}px;
  `}
`;

const SectionDescription = styled(ReactMarkdown)`
   * {
    font-family: ${p => p.theme.fontFamily};
    font-size: ${p => p.theme.fontSize.extraSmall}px;
    color: ${p => p.theme.colors.textSenary};
    line-height: ${p => p.theme.lineHeight.large};
    font-weight: ${p => p.theme.fontWeight.normal};
    margin: ${p => p.theme.setSpacing(2)}px 0;
    text-align: center;

    ${media.desktop`
      margin: ${p => p.theme.setSpacing(4)}px 0;
      text-align: left;

      a {
        &:hover {
          opacity: .8;
        }
      }
    `}
  }
  ${p => p.theme.utils.resetLinkColor(p.theme.colors.textSenary)};
`;

const AWARD_ICON = 'award-icon';
const TOWER_ICON = 'tower-icon';

const renderIcon = (icon: string) => {
  switch (icon) {
    case AWARD_ICON:
      return awardIcon;
    case TOWER_ICON:
      return towerIcon;
    default:
      return awardIcon;
  }
};

type Props = {
  commitments: Array<Object>,
}

export const Commitments = (props: Props) => {
  const { commitments } = props;

  const orderedCommitments = commitments && commitments.sort((a, b) => {
    if (a.fields && a.fields.order && b.fields && b.fields.order) {
      return (a.fields.order > b.fields.order) ? 1 : -1;
    }
    return 0;
  });

  return (
    <CommitmentsContainer>
      <ContentContainer>
        <CommitmentHeading>Our Commitments</CommitmentHeading>
        <FlexedOuterContainer>
          {orderedCommitments
            && orderedCommitments.map(((commitment: Object) => {
              const {
                fields: {
                  title,
                  description,
                  icon,
                },
              } = commitment;

              return (
                <CommitmentSectionContainer key={title}>
                  <CommitmentTitleFlexWrapper>
                    <CommitmentIcon image={renderIcon(icon)} />
                    <CommitmentTitle>
                      {title}
                    </CommitmentTitle>
                  </CommitmentTitleFlexWrapper>
                  <SectionDescription linkTarget='_blank'>
                    {description}
                  </SectionDescription>
                </CommitmentSectionContainer>
              );
            }))
          }
        </FlexedOuterContainer>
      </ContentContainer>
    </CommitmentsContainer>
  );
};
