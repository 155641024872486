// @flow
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { createID } from './index';
import { renderResources } from './resource';
import { media } from '../../theme';

const CategoryWrapper = styled.div`
  padding-top: ${p => p.theme.setSpacing(7)}px;

  &:first-of-type {
    &:first-child {
      padding-top: ${p => p.theme.setSpacing(4)}px;
    }
  }
`;
const CategoryHeading = styled.div`
  padding-bottom: ${p => p.theme.setSpacing(2)}px;
  border-bottom: 1px solid ${p => p.theme.colors.borderSecondary};
`;

const CategoryDescription = styled(ReactMarkdown)`
  * {
    font-size: ${p => p.theme.fontSize.small}px;
    font-weight: ${p => p.theme.fontWeight.thin};
    line-height: ${p => p.theme.lineHeight.medium};
    color: ${p => p.theme.colors.textPrimary};
    max-width: ${p => p.theme.setSpacing(80)}px;
    text-align: left;
    margin: ${p => p.theme.setSpacing(2.25)}px auto;

    ${media.desktop`
      font-size: ${p => p.theme.fontSize.small}px;
      margin: ${p => p.theme.setSpacing(2.25)}px 0;

      a {
        &:hover {
          opacity: .8;
        }
      }
    `}
  }

  ul {
    padding-left: ${p => p.theme.setSpacing(2)}px;
  }
  ${p => p.theme.utils.resetLinkColor(p.theme.colors.textPrimary)};
`;

const CategoryTitle = styled.h2`
  font-size: ${p => p.theme.fontSize.large}px;
  color: ${p => p.theme.colors.textQuinary};
  font-weight: ${p => p.theme.fontWeight.thin};
  margin: 0;
  display: block;
  width: 100%;
  text-align: left;

  ${media.desktop`
    display: inline-block;
    width: auto;
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  ${media.desktop`
    flex-wrap: nowrap;
  `}
`;

const CategoryIcon = styled.img`
  width: 40px;
  height: 40px;
  display: block;
  margin: 0 0 ${p => p.theme.setSpacing(2)}px;

  ${media.desktop`
    display: inline-block;
    margin: 0 ${p => p.theme.setSpacing(2)}px 0 0;
  `}
`;

const renderIcon = (icon: Object) => {
  const { fields: { file: { url } } } = icon;

  return <CategoryIcon src={url} />;
};

const renderTitle = (
  title: string,
  description: string,
  icon: Object,
) => (
  <CategoryHeading id={`${createID(title)}-heading`}>
    <TitleWrapper>
      {icon && renderIcon(icon)}
      <CategoryTitle>{title}</CategoryTitle>
    </TitleWrapper>
    <CategoryDescription linkTarget='_blank'>{description}</CategoryDescription>
  </CategoryHeading>
);

type Props = {
  category: Object,
}

export const Category = (props: Props) => {
  const { category: { fields } } = props;
  return (
    <CategoryWrapper
      id={createID(fields.categoryName)}
      key={fields.categoryName}
    >
      {renderTitle(
        fields.categoryName,
        fields.categoryDescription,
        fields.icon,
      )}
      {renderResources(fields.resources)}
    </CategoryWrapper>
  );
};
