// @flow
import React from 'react';
import styled from 'styled-components';
import { ContentContainer } from '../content-container';
import { NewsPost } from './news-post';
import { media } from '../../theme';

const NewsContainer = styled.div`
  background-color: ${p => p.theme.colors.backgroundSecondary};
  padding: ${p => p.theme.setSpacing(6)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(12)}px 0;
  `}
`;

const NewsHeading = styled.h1`
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.theme.fontSize.large}px;
  color: ${p => p.theme.colors.textSecondary};
  font-weight: ${p => p.theme.fontWeight.semiBold};
  margin-top: 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.huge}px;
  `}
`;

type Props = {
  posts: Array<Object>
}

export const News = (props: Props) => {
  const { posts } = props;

  const orderedPosts = posts && posts.sort((a, b) => {
    if (a.fields && a.fields.order && b.fields && b.fields.order) {
      return (a.fields.order > b.fields.order) ? 1 : -1;
    }
    return 0;
  });

  return (
    <NewsContainer>
      <ContentContainer>
        <NewsHeading>News</NewsHeading>
        {(orderedPosts.length > 0) && orderedPosts.map(({ fields, sys }) => (
          <NewsPost
            key={sys.id}
            {...fields}
          />
        ))}
      </ContentContainer>
    </NewsContainer>
  );
};
