// @flow

import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import Geocode from 'react-geocode';
import { Button } from '../button';
import { ErrorModal } from '../error-modal';
import { FormInput } from '../input';
import { FormDropdown } from './dropdown';
import { SearchResults } from './search-results';
import {
  BOROUGHS_ARRAY,
  FACILITY_TYPES_ARRAY,
} from '../../constants/efp-form';
import { GMAP_API_KEY } from '../../constants/api';
import { createDirectoryQuery } from '../../utils/directory-query';
import { searchEFPDirectory } from '../../services/api';
import { media } from '../../theme';

const FormWrapper = styled.div`
  padding: 0 ${p => p.theme.setSpacing(4)}px;
  max-width: 950px;
  margin: 0 auto;

  ${media.tablet`
    padding: 0 ${p => p.theme.setSpacing(8)}px ${p => p.theme.setSpacing(4)}px;
  `}
`;

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: ${p => p.theme.setSpacing(6)}px auto;

  ${media.tablet`
    width: 75%;
  `}
`;

const SearchButton = styled(Button)`
  justify-content: center;
`;

const ResetButton = styled(SearchButton)`
  border: 1px solid ${p => p.theme.colors.buttonPrimaryColor};
  margin-right: ${p => p.theme.setSpacing(3)}px;

  ${media.desktop`
    &:hover {
      background-color: ${p => p.theme.colors.buttonPrimaryColor};
      color: ${p => p.theme.colors.buttonPrimaryText};
    }
  `}
`;

type State = {
  isEmptyState: boolean,
  isNoResultsState: boolean,
  isLoading: boolean,
  error: boolean,
  entries: any,
  programName: string,
  city: string,
  zip: string,
  borough: string,
  facilityType: string,
}

const INITIAL_STATE = {
  isEmptyState: true,
  isNoResultsState: false,
  isLoading: false,
  error: false,
  entries: [],
  programName: '',
  city: '',
  zip: '',
  borough: '',
  facilityType: '',
};

export class Form extends PureComponent<{}, State> {
  state = INITIAL_STATE;

  handleChange = (e: any, property: string) => {
    const { target: { value } } = e;
    this.setState(() => ({ [property]: value }));
  }

  handleResetForm = () => this.setState(() => ({ ...INITIAL_STATE }));

  setEntries = (response: Object) => {
    const { items } = response;
    const entries = items;
    if (entries.length === 0) {
      this.setState(() => ({
        isNoResultsState: true,
        isEmptyState: false,
        isLoading: false,
        entries: [],
      }));
      return;
    }
    Geocode.setApiKey(GMAP_API_KEY);

    const createGeoCode = async entry => Geocode.fromAddress(
      entry.fields.address,
    )
      .then((res: any) => {
        if (res.status === 'OK') {
          const { fields } = entry;
          const { lat, lng } = res.results[0].geometry.location;
          const newEntry = { lat, lng, ...fields };
          // console.log(newEntry);
          return newEntry;
        }
        return [];
      });

    const getAllGeocodedEntries = async () => {
      const newArray = [];

      // eslint-disable-next-line
      for (const entry of entries) {
        // eslint-disable-next-line
        const geoEntry = await createGeoCode(entry);
        newArray.push(geoEntry);
      }

      return newArray;
    };

    getAllGeocodedEntries().then(newEntries => this.setState(() => ({
      isEmptyState: false,
      isNoResultsState: false,
      isLoading: false,
      entries: newEntries,
    })));
  };

  closeModal = () => this.setState(() => ({ error: false }));

  renderErrorModal = () => {
    const { error } = this.state;

    return !error ? null : (
      <ErrorModal error={error} closeModal={this.closeModal} />
    );
  }

  catchError = () => this.setState(() => ({
    error: true,
    isLoading: false,
  }))

  handleFormSubmit = () => {
    const {
      programName,
      city,
      zip,
      borough,
      facilityType,
    } = this.state;

    const queryObject = {
      programName,
      city,
      zip,
      borough,
      facilityType,
    };

    if (!programName && !city && !zip && !borough && !facilityType) {
      return;
    }

    this.setState(() => ({ isLoading: true }));

    const QueryUrl = createDirectoryQuery(queryObject);

    searchEFPDirectory(QueryUrl)
      .then(res => this.setEntries(res))
      .catch(this.catchError);
  }

  render() {
    const {
      isEmptyState,
      isNoResultsState,
      isLoading,
      entries,
      programName,
      city,
      zip,
      borough,
      facilityType,
    } = this.state;

    const OPTIONS = [
      {
        label: 'Program Name',
        id: 'programName',
        value: programName,
        onChange: e => this.handleChange(e, 'programName'),
      },
      {
        label: 'City',
        id: 'city',
        value: city,
        onChange: e => this.handleChange(e, 'city'),
      },
      {
        label: 'Zip Code',
        id: 'zip',
        value: zip,
        onChange: e => this.handleChange(e, 'zip'),
      },
    ];

    const BOROUGHS = {
      label: 'Borough',
      id: 'borough',
      value: borough,
      onChange: e => this.handleChange(e, 'borough'),
      options: BOROUGHS_ARRAY,
    };

    const FACILITY_TYPES = {
      label: 'Program Type',
      id: 'facilityType',
      value: facilityType,
      onChange: e => this.handleChange(e, 'facilityType'),
      options: FACILITY_TYPES_ARRAY,
    };

    return (
      <Fragment>
        <FormWrapper>
          {OPTIONS.map(option => (
            <FormInput
              key={option.id}
              {...option}
            />
          ))}
          <FormDropdown {...BOROUGHS} />
          <FormDropdown {...FACILITY_TYPES} />
          <ButtonsWrapper>
            <ResetButton
              isInverted
              onClick={this.handleResetForm}
            >
              Reset
            </ResetButton>
            <SearchButton
              onClick={this.handleFormSubmit}
            >
              Search
            </SearchButton>
          </ButtonsWrapper>
        </FormWrapper>
        <SearchResults
          isEmptyState={isEmptyState}
          entries={entries}
          isNoResultsState={isNoResultsState}
          isLoading={isLoading}
        />
        {this.renderErrorModal()}
      </Fragment>
    );
  }
}
