// @flow
import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

type Props = {
  location: {
    pathname: String,
  },
  children: any,
}

class ScrollToTop extends PureComponent<Props> {
  componentDidUpdate(prevProps) {
    const { location: { pathname } } = this.props;
    const previousPathname = prevProps.location.pathname;
    if (pathname !== previousPathname) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ScrollToTop);
