// @flow
import { css } from 'styled-components';
import { colors } from './colors';

const bgImgCover = `
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

const carouselColors = (color: string) => {
  switch (color) {
    case 'light blue':
      return css`background-color: ${colors.backgroundTertiary}`;
    case 'light green':
      return css`background-color: ${colors.backgroundQuinary}`;
    case 'dark green':
      return css`background-color: ${colors.backgroundSecondary}`;
    case 'dark blue':
      return css`background-color: ${colors.backgroundQuaternary}`;
    default:
      return css`background-color: ${colors.backgroundSecondary}`;
  }
};

const resetLinkColor = (color: string) => css`
  * > a {
    color: ${color};
  }
`;

export const utils = {
  bgImgCover,
  carouselColors,
  resetLinkColor,
};
