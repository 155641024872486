// @flow

import React, { Component } from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import styled from 'styled-components';
import { GMAP_API_KEY } from '../../constants/api';
import markerActiveIcon from '../../assets/images/icons/marker-active-3.png';
import markerIcon from '../../assets/images/icons/marker-inactive-3.png';
import { media } from '../../theme';

const MapOuter = styled.div`
  height: 100%;
`;

const MapInner = styled.div`
  height: 300px;
  ${p => p.theme.fullWidthMobileOnly}
  margin-bottom: ${p => p.theme.setSpacing(2)}px;

  ${media.tablet`
    position: relative;
    height: 800px;
    margin: 0;
  `}
`;

const googleMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${GMAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`

type Props = {
  activeEntry: Object,
  entries: Array<Object>,
  onMarkerClick: Function,
}

export class MapComponent extends Component<Props> {
  // eslint-disable-next-line
  constructor(props: any) {
    super(props);
  }

  shouldComponentUpdate(nextProps: any) {
    // eslint-disable-next-line
    if (this.props.activeEntry === nextProps.activeEntry) {
      return false;
    }
    return true;
  }

  getMapDefaults = () => {
    const { activeEntry } = this.props;
    const { lat, lng } = activeEntry;
    return ({
      zoom: 15,
      center: { lat, lng },
      options: {
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
      },
    });
  };

  gMap: any;

  render() {
    const {
      entries,
      onMarkerClick,
      activeEntry,
    } = this.props;

    const GMapComponent = withGoogleMap(() => (
      <GoogleMap
        {...this.getMapDefaults()}
      >
        {(entries && entries.length > 0)
          && entries.map((entry) => {
            const { efroid, lat, lng } = entry;
            const markerActive = (efroid === activeEntry.efroid);
            return (
              <Marker
                id={efroid}
                key={efroid}
                position={{ lat, lng }}
                icon={markerActive ? markerActiveIcon : markerIcon}
                zIndex={markerActive ? 20 : 10}
                onClick={() => onMarkerClick(entry)}
              />
            );
          })}
      </GoogleMap>
    ));

    return (
      <GMapComponent
        googleMapURL={googleMapsUrl}
        mapElement={<MapOuter />}
        loadingElement={<MapOuter />}
        containerElement={<MapInner />}
      />
    );
  }
}
