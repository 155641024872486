// @flow
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Download } from '../download';
import { media } from '../../theme';
import { ExternalLink } from '../external-link';

const PostContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-top: 2px solid ${p => p.theme.colors.borderTertiary};
  padding: ${p => p.theme.setSpacing(2)}px 0;

  ${media.desktop`
    padding: ${p => p.theme.setSpacing(6)}px 0;
    flex-wrap: nowrap;
  `}
`;

const NewsTitle = styled.h2`
  font-family: ${p => p.theme.fontFamily};
  font-size: ${p => p.theme.fontSize.medium}px;
  font-weight: ${p => p.theme.fontWeight.regular};
  line-height: ${p => p.theme.lineHeight.medium};
  color: ${p => p.theme.colors.textSecondary};
  margin-top: 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.large}px;
  `}
`;

const NewsContentBlock = styled.div`
  width: 100%;

  ${media.desktop`
    width: ${p => p.theme.setSpacing(72)}px;
  `}
`;

const NewsTitleBlock = styled.div`
  width: 100%;

  ${media.desktop`
    width: ${p => p.theme.setSpacing(42)}px;
  `}
`;

const NewsDescription = styled(ReactMarkdown)`
  * {
    font-family: ${p => p.theme.fontFamily};
    font-size: ${p => p.theme.fontSize.extraSmall}px;
    color: ${p => p.theme.colors.textSecondary};
    line-height: ${p => p.theme.lineHeight.large};
    font-weight: ${p => p.theme.fontWeight.normal};
    margin-top: 0;
    margin-bottom: ${p => p.theme.setSpacing(5)}px;
  }

  ${media.desktop`
    a {
      &:hover {
        opacity: .8;
      }
    }
  `}
`;

type Props = {
  title: string,
  description: string,
  resources?: Object,
  externalUrl?: string,
  externalUrlTitle?: string,
}

export const NewsPost = (props: Props) => {
  const {
    title,
    description,
    resources,
    externalUrl,
    externalUrlTitle,
  } = props;

  const linkButton = (
    <ExternalLink
      url={externalUrl || ''}
      text={externalUrlTitle || ''}
    />
  );

  const resourceDocuments = resources
    && resources.fields
    && resources.fields.documents;

  const renderResources = () => {
    if (resourceDocuments && externalUrl && externalUrlTitle) {
      return resourceDocuments;
    } if (externalUrl && externalUrlTitle) {
      return linkButton;
    } if (resourceDocuments) {
      return <Download pdfs={resourceDocuments} />;
    }
    return false;
  };

  return (
    <PostContainer>
      <NewsTitleBlock>
        <NewsTitle>
          {title}
        </NewsTitle>
      </NewsTitleBlock>
      <NewsContentBlock>
        <NewsDescription linkTarget='_blank'>
          {description}
        </NewsDescription>
        {renderResources()}
      </NewsContentBlock>
    </PostContainer>
  );
};

NewsPost.defaultProps = {
  resources: [],
  externalUrl: '',
  externalUrlTitle: '',
};
