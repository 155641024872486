// @flow
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Button } from './button';
import ClickOutside from './click-outside';
import { media } from '../theme';
import chevronBlueDown from '../assets/images/icons/chevron-blue-down.svg';
import whiteArrow from '../assets/images/icons/white-arrow.svg';
import blueArrow from '../assets/images/icons/arrow-right-blue.svg';
import chevronWhiteDown from '../assets/images/icons/chevron-white-down.svg';

const DownloadsWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  width: 100%;

  ${media.desktop`
    flex-wrap: nowrap;
    align-items: center;
    width: auto;
  `}
`;

const ButtonWrapper = styled.div`
  width: 100%;
  order: 1;
  margin-top: ${p => p.theme.setSpacing(2)}px;

  ${media.desktop`
    margin-top: 0;
    width: auto;
    ${p => (p.isSecondary ? 'margin-left: 16px;' : '')};

    ${p => (
    (p.isSecondary || p.isTertiary) ? `
          order: 1;
        ` : `
          order: 0;
        `
  )}
  `}
`;

const ClickOutsideWrap = styled(ClickOutside)`
  width: 100%;
`;

const OptionsWrapper = styled.div`
  width: 100%;

  ${p => (p.isTertiary
    ? `
      margin-right: ${p.theme.setSpacing(5)}px;
    `
    : ''
  )}

  ${media.desktop`
    ${p => (!(p.isTertiary || p.isSecondary)
    ? `
    margin-left: ${p.theme.setSpacing(2)}px;
    `
    : ''
  )}
  `}
`;

const Option = styled(Button)`
  color: ${p => p.theme.colors.buttonPrimaryColor};
  background-color: ${p => p.theme.colors.buttonPrimaryText};
  margin-left: 0;
  ${p => ((p.isSecondary || p.isTertiary) ? 'width: 120px;' : '')};
  font-weight: ${p => (p.active ? p.theme.fontWeight.semiBold : p.theme.fontWeight.thin)};

  ${media.desktop`
    &:hover {
      background-color: ${p => p.theme.colors.buttonPrimaryText}
    }
  `}
`;

const ActiveOption = styled(Option)`
  margin-left: 0;
  margin-top: ${p => p.theme.setSpacing(1)}px;
  ${p => (p.isSecondary
    ? `
      border-bottom: 1px solid ${p.theme.colors.textQuinary};
      width: 120px;
      padding: ${p.theme.setSpacing(1.5)}px ${p.theme.setSpacing((1 / 2))}px;
    `
    : ''
  )}

  ${p => (p.isTertiary
    ? `
      background: transparent;
      color: ${p.theme.colors.textSecondary};
      border-bottom: 2px solid ${p.theme.colors.textSecondary};
      width: 120px;
      padding: ${p.theme.setSpacing(1.5)}px ${p.theme.setSpacing((1 / 2))}px;
    `
    : ''
  )}

  ${media.desktop`
    margin-top: 0;
    margin-bottom: 0;
  `}
`;

const Select = styled.div`
  display: ${p => (p.menuOpen ? 'block' : 'none')};
  position: absolute;
  z-index: 20;
  max-height: 200px;
  overflow-y: scroll;
  border: 1px solid ${p => p.theme.colors.borderPrimary};
  ${p => ((p.isSecondary || p.isTertiary) ? '' : 'width: 100%;')}

  ${media.desktop`
    width: auto;
  `}
`;

const renderDownloadButton = (
  pdf: Object,
  isSecondary: boolean,
  isTertiary: boolean,
) => {
  const { file, title } = pdf.fields.file.fields;
  return (
    <ButtonWrapper
      isSecondary={isSecondary}
      isTertiary={isTertiary}
    >
      <Button
        key={title}
        href={file && file.url}
        download={title}
        target='_blank'
        icon={isTertiary ? blueArrow : whiteArrow}
        isInverted={isTertiary}
        isSmall={isSecondary}
      >
        Download
      </Button>
    </ButtonWrapper>
  );
};


const SingleOption = (
  pdf: Object,
  menuOpen: boolean,
  onClick: Function,
  isSecondary: boolean,
  isTertiary: boolean,
) => (
  <ActiveOption
    key={pdf.fields && pdf.fields.language}
    menuOpen={menuOpen}
    onClick={() => onClick(pdf)}
    isSecondary={isSecondary}
    isTertiary={isTertiary}
    icon={isTertiary ? chevronWhiteDown : chevronBlueDown}
  >
    {pdf.fields && pdf.fields.language}
  </ActiveOption>
);

const renderAllOptions = (
  pdfs: Array<Object>,
  menuOpen: boolean,
  onClick: Function,
  isSecondary: boolean,
  isTertiary: boolean,
  activePdf: Object,
) => (
  <Select
    menuOpen={menuOpen}
    isSecondary={isSecondary}
    isTertiary={isTertiary}
  >
    {pdfs.map((pdf) => {
      const language = pdf.fields && pdf.fields.language;
      const activeLanguage = activePdf.fields && activePdf.fields.language;
      return (
        <Option
          onClick={() => onClick(pdf)}
          key={language}
          isSecondary={isSecondary}
          isTertiary={isTertiary}
          active={language === activeLanguage}
        >
          {language}
        </Option>
      );
    })}
  </Select>
);

type Props = {
  pdfs: Array<Object>,
  isSecondary?: boolean,
  isTertiary?: boolean,
};

type State = {
  activePdf: Object,
  menuOpen: boolean,
};

export class Download extends PureComponent<Props, State> {
  static defaultProps = {
    isSecondary: false,
    isTertiary: false,
  }

  constructor(props: Props) {
    super(props);

    const { pdfs } = props;
    this.state = {
      activePdf: pdfs[0],
      menuOpen: false,
    };
  }

  handleMenuOpenClick = (pdf: Object) => {
    const { menuOpen } = this.state;

    this.setState(() => ({
      menuOpen: !menuOpen,
      activePdf: pdf,
    }));
  }

  handleClickOutside = () => {
    const { menuOpen } = this.state;

    if (menuOpen) {
      this.setState(() => ({
        menuOpen: false,
      }));
    }
  }

  render() {
    const { activePdf, menuOpen } = this.state;
    const {
      pdfs,
      isSecondary,
      isTertiary,
    } = this.props;

    return (
      <DownloadsWrapper>
        {
          activePdf
          && renderDownloadButton(
            activePdf,
            isSecondary || false,
            isTertiary || false,
          )
        }
        {
          (activePdf && pdfs.length > 1)
          && (
            <ClickOutsideWrap onClickOutside={this.handleClickOutside}>
              <OptionsWrapper isTertiary={isTertiary}>
                {
                  SingleOption(
                    activePdf,
                    menuOpen,
                    this.handleMenuOpenClick,
                    isSecondary || false,
                    isTertiary || false,
                  )
                }
                {
                  renderAllOptions(
                    pdfs,
                    menuOpen,
                    pdf => this.handleMenuOpenClick(pdf),
                    isSecondary || false,
                    isTertiary || false,
                    activePdf,
                  )
                }
              </OptionsWrapper>
            </ClickOutsideWrap>
          )
        }
      </DownloadsWrapper>
    );
  }
}
