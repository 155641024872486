// @flow
import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { Download } from '../download';
import { ExternalLink } from '../external-link';
import { media } from '../../theme';

const ResourceWrapper = styled.div`
  border-bottom: 1px solid ${p => p.theme.colors.borderSecondary};
  padding: ${p => p.theme.setSpacing(2)}px 0 ${p => p.theme.setSpacing(3)}px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  ${media.desktop`
    flex-wrap: nowrap;
  `}
`;

const ResourceText = styled.div`
  width: 100%;

  ${media.desktop`
    max-width: ${p => p.theme.setSpacing(60)}px;
  `}
`;

const ResourceTitle = styled.h3`
  font-size: ${p => p.theme.fontSize.small}px;
  color: ${p => p.theme.colors.textQuinary};
  font-weight: ${p => p.theme.fontWeight.semiBold};
  display: block;
  width: 100%;
  margin: ${p => p.theme.baseSpacing}px 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.medium}px;
    display: inline-block;
  `}
`;

const ResourceDescription = styled(ReactMarkdown)`
  * {
    font-size: ${p => p.theme.fontSize.extraSmall}px;
    color: ${p => p.theme.colors.textPrimary};
    font-weight: ${p => p.theme.fontWeight.thin};
    line-height: ${p => p.theme.lineHeight.small};
    margin:
      ${p => p.theme.setSpacing(2)}px
      ${p => p.theme.setSpacing(2)}px
      ${p => p.theme.setSpacing(3)}px
      0;
  }

  ${p => p.theme.utils.resetLinkColor(p.theme.colors.textQuinary)};

  ${media.desktop`
    a {
      &:hover {
        opacity: .8;
      }
    }
    `}
`;

const ResourceDate = styled.p`
  font-size: ${p => p.theme.fontSize.tiny}px;
  color: ${p => p.theme.colors.textPrimary};
  font-weight: ${p => p.theme.fontWeight.thin};
  margin: ${p => p.theme.setSpacing(1 / 2)}px 0;
`;

export const renderResources = (resources: Array<Object> = []) => (
  resources.map<Object>((resource) => {
    const {
      fields: {
        description,
        title,
        documents,
        externalLinkUrl,
        externalLinkText,
      },
      sys: {
        updatedAt,
      },
    } = resource;

    const linkButton = externalLinkUrl
    && externalLinkText
    && (
      <ExternalLink
        url={externalLinkUrl}
        text={externalLinkText}
        isSecondary
      />
    );

    const renderedDate = new Date(updatedAt).toLocaleDateString('en-US') || '';

    return (
      <ResourceWrapper key={title}>
        <ResourceText>
          { renderedDate
            && (
              <ResourceDate>
                {`updated ${renderedDate}`}
              </ResourceDate>
            )
          }
          <ResourceTitle>{title}</ResourceTitle>
          <ResourceDescription linkTarget='_blank'>{description}</ResourceDescription>
        </ResourceText>
        {documents && <Download isSecondary pdfs={documents} />}
        {linkButton}
      </ResourceWrapper>
    );
  })
);
