// @flow

import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { media } from '../theme';
import SVGClose from '../assets/images/icons/modal-close.svg';
import SVGCloseDark from '../assets/images/icons/modal-close-dark.svg';

// eslint-disable-next-line
const modalRoot = document.getElementById('general-modal-root');

const GeneralModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${p => (p.isSecondary
    ? p.theme.colors.transparentDarkOverlay
    : p.theme.colors.transparentOverlay
  )};
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalInner = styled.div`
  background-color: ${p => p.theme.colors.backgroundQuaternary};

  ${p => (p.isSecondary
    ? `
      width: 90%;
      height: auto;
      max-width: 1280px;
    `
    : `
      width: 300px;
      height: 242px;
    `
  )}
  position: relative;

  ${media.desktop`
    width: ${p => (p.isSecondary
    ? '90%'
    : '600px'
  )};
  `}
`;

const CloseIcon = styled.img`
  width: 100%;
  height: 100%;
  cursor: pointer;

  ${media.desktop`
      &:hover {
        opacity: .6;
      }
  `}
`;

const CloseButton = styled.button`
  width: ${p => p.theme.setSpacing(4)}px;
  height: ${p => p.theme.setSpacing(4)}px;
  position: absolute;
  top: ${p => p.theme.setSpacing(3)}px;
  right: ${p => p.theme.setSpacing(3)}px;
  padding: 0;
  border: none;
  outline: none;
  z-index: 100;
  background-color: transparent;
  cursor: pointer;

`;

type Props = {
  children: any,
  closeModal: Function,
  isModalOpen: boolean,
  isSecondary?: boolean,
}

class ModalComponent extends PureComponent<Props> {
  static defaultProps = {
    isSecondary: false,
  }

  constructor(props: Props) {
    super(props);

    // eslint-disable-next-line no-undef
    this.document = document;

    this.el = this.document.createElement('div');
  }

  componentDidMount() {
    // $FlowFixMe
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    // $FlowFixMe
    modalRoot.removeChild(this.el);
  }

  el: any;

  document: any;

  renderContainer = () => {
    const {
      children,
      closeModal,
      isSecondary,
    } = this.props;

    return (
      <GeneralModal isSecondary={isSecondary}>
        <ModalInner isSecondary={isSecondary}>
          <CloseButton
            onClick={closeModal}
          >
            <CloseIcon
              src={isSecondary ? SVGCloseDark : SVGClose}
              alt='modal-close'
            />
          </CloseButton>
          {children}
        </ModalInner>
      </GeneralModal>
    );
  }

  render() {
    const { isModalOpen } = this.props;
    if (!isModalOpen) return null;

    return ReactDOM.createPortal(
      this.renderContainer(),
      this.el,
    );
  }
}

export { ModalComponent };
