// @flow

import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import { ResultsTab } from './results-tab';
import { ResultsContent } from './results-content';
import { LoaderComponent } from '../loader';
import { TABS } from '../../constants/efp-form';
import { media } from '../../theme';

const ResultsWrapper = styled.div`
  background-color: ${p => p.theme.colors.backgroundTertiary};
  padding: ${p => p.theme.setSpacing(5)}px 0;
  min-height: 800px;

  ${media.tablet`
    padding: ${p => p.theme.setSpacing(10)}px 0;
    min-height: 800px;
  `}
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const renderTabs = (
  labels: Array<string>,
  activeTab: string,
  onClick: Function,
) => labels.map(label => (
  <ResultsTab
    key={label}
    activeTab={label === activeTab}
    onClick={onClick}
    label={label}
  />
));

type Props = {
  entries: any,
  isEmptyState: boolean,
  isNoResultsState: boolean,
  isLoading: boolean,
}

type State = {
  activeTab: string,
}

export class SearchResults extends PureComponent<Props, State> {
  state = {
    activeTab: TABS[0],
  }

  handleTabClick = (tab: string) => this.setState(() => ({
    activeTab: tab,
  }));

  renderLoadedContent = () => {
    const { activeTab } = this.state;

    return (
      <Fragment>
        <TabsWrapper>
          {renderTabs(
            TABS,
            activeTab,
            this.handleTabClick,
          )}
        </TabsWrapper>
        <ResultsWrapper>
          <ResultsContent
            {...this.props}
            activeTab={activeTab}
          />
        </ResultsWrapper>
      </Fragment>
    );
  }

  render() {
    const { isLoading } = this.props;

    return isLoading ? <LoaderComponent isContainerLoader /> : this.renderLoadedContent();
  }
}
