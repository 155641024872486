// @flow
import React, { Fragment } from 'react';
import { AboutContent } from '../components/about';

type Props = {
  location: {
    hash: string,
  }
}

export const AboutView = (props: Props) => {
  const { location: { hash } } = props;
  return (
    <Fragment>
      <AboutContent hash={hash} />
    </Fragment>
  );
};
