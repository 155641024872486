// @flow

import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import { media } from '../../theme';
import { BioModal } from './bio-modal';
import { sizes } from '../../theme/media-queries';
import chevronBlueDown from '../../assets/images/icons/chevron-blue-down.svg';

const photoSize = '240px';

const CardWrap = styled.div`
  width: 100%;
  margin: 0;
  cursor: pointer;
  border-bottom: 1px solid ${p => p.theme.colors.borderPrimary};
  padding-bottom: ${p => p.theme.setSpacing(2)}px;
  position: relative;

  ${media.desktop`
    border-bottom: none;
    width: ${photoSize};
    margin:
    ${p => p.theme.setSpacing(1)}px
    ${p => p.theme.setSpacing(5)}px
    ${p => p.theme.setSpacing(5)}px
    0;

    &:hover {
      img.bio-photo {
        box-shadow: 0 6px 15px rgba(0,0,0, .3);
      }
      div > h3 {
        color: ${p => p.theme.colors.textSenary};
      }
    }
  `}
`;

const Photo = styled.img`
  width: ${photoSize};
  height: ${photoSize};
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: center;';
  display: none;
  transition: all .2s linear;

  ${media.desktop`
    display: block;
  `}
`;

const TextWrapper = styled.div`
  margin: ${p => p.theme.setSpacing(2)}px 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: ${p => p.theme.setSpacing(3)}px;
`;

const Name = styled.h3`
  font-size: ${p => p.theme.fontSize.medium}px;
  color: ${p => p.theme.colors.textQuinary};
  font-weight: ${p => p.theme.fontWeight.normal};
  margin: 0 0 ${p => p.theme.setSpacing(1.5)}px;
  width: 100%;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.medium}px;
  `}
`;

const Title = styled.p`
  font-size: ${p => p.theme.fontSize.extraSmall}px;
  color: ${p => p.theme.colors.textQuinary};
  font-weight: ${p => p.theme.fontWeight.normal};
  margin: 0;
  width: 100%;
  padding-right: ${p => p.theme.setSpacing(3)}px;

  ${media.desktop`
    padding-right: 0;
  `}
`;

const Arrow = styled.img`
  width: ${p => p.theme.setSpacing(2)}px;
  transition: all .2s linear;
  position: absolute;
  right: 0px;
  top: ${p => p.theme.setSpacing(3)}px;

  ${p => (p.mobileBioShown ? 'transform: rotate(180deg);' : '')}
  ${media.desktop`
    display: none;
  `}
`;

const BioContentBlock = styled.div`
  width: 100%;
  transition: all .2s ease-in;
  height: ${p => (p.mobileBioShown ? '100%' : '0px')};
  overflow: hidden;

  ${media.desktop`
    display: none;
  `}
`;

const BioText = styled(ReactMarkdown)`
  * {
    color: ${p => p.theme.colors.textPrimary};
    font-size: ${p => p.theme.fontSize.extraSmall}px;
    line-height: ${p => p.theme.lineHeight.medium};
  }

  ${media.desktop`
    a {
      &:hover {
        opacity: .8;
      }
    }
    `}
  ${p => p.theme.utils.resetLinkColor(p.theme.colors.textPrimary)};
`;

type State = {
  mobileBioShown: boolean,
  modalOpen: boolean,
}

type Props = {
  name: string,
  title: string,
  photo: Object,
  bio: string,
}

export class MemberCard extends PureComponent<Props, State> {
  state = {
    mobileBioShown: false,
    modalOpen: false,
  }

  handleCardClick = () => {
    if (window.innerWidth >= sizes.desktop) {
      this.handleBioDesktopModal();
    } else {
      this.handleMobileBioOpen();
    }
  }

  handleMobileBioOpen = () => {
    const { mobileBioShown } = this.state;

    this.setState(() => ({
      mobileBioShown: !mobileBioShown,
    }));
  }

  handleBioDesktopModal = () => this.setState(() => ({ modalOpen: true }));

  closeModal = () => this.setState(() => ({ modalOpen: false }));

  renderBioModal = () => {
    const { modalOpen } = this.state;

    return !modalOpen ? null : (
      <BioModal
        isModalOpen={modalOpen}
        closeModal={this.closeModal}
        {...this.props}
      />
    );
  }

  render() {
    const {
      name,
      title,
      photo,
      bio,
    } = this.props;

    const { mobileBioShown } = this.state;

    const renderPhoto = () => {
      const {
        fields: {
          file: {
            url,
          },
        },
      } = photo;

      return (
        <Photo
          src={url}
          alt={name}
          className='bio-photo'
        />
      );
    };


    return (
      <Fragment>
        <CardWrap onClick={this.handleCardClick}>
          { photo && renderPhoto()}
          <TextWrapper>
            <Name>{name}</Name>
            <Title>{title}</Title>
            <Arrow
              src={chevronBlueDown}
              alt='dropdown arrow'
              mobileBioShown={mobileBioShown}
            />
          </TextWrapper>
        </CardWrap>
        <BioContentBlock
          mobileBioShown={mobileBioShown}
        >
          <BioText linkTarget='_blank'>
            {bio}
          </BioText>
        </BioContentBlock>
        {this.renderBioModal()}
      </Fragment>
    );
  }
}
