// @flow

import React, { Fragment, PureComponent } from 'react';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { Waypoint } from 'react-waypoint';
import { ContentContainer } from '../content-container';
import { MapComponent } from './map';
import { MapSingleResult } from './map-single-result';
import { ListSingleResult } from './list-single-result';
import { ListColumns } from './list-columns';
import { MAP_TAB } from '../../constants/efp-form';
import leafImg from '../../assets/images/leaf.png';
import truckImg from '../../assets/images/truck.png';
import { media } from '../../theme';

const EmptyStateWrapper = styled.div`
  padding: ${p => p.theme.setSpacing(12)}px 0;
`;

const EmptyStateImg = styled.img`
  height: 66px;
  margin: 0 auto;
  display: block;
`;

const EmptyStateText = styled.h2`
  text-align: center;
  font-size: ${p => p.theme.fontSize.extraLarge}px;
  font-weight: ${p => p.theme.fontWeight.bold};
  color: ${p => p.theme.colors.textSenary};
`;

const ResultsFlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${media.tablet`
    flex-wrap: nowrap;
    justify-content: space-between;
  `}
`;

const MapList = styled.div`
  width: 100%;
  order: 2;

  ${media.tablet`
    width: 50%;
    order: 0;
  `}
`;

const FullWidthList = styled.div`
  width: 100%;
`;

const RelativeMapWrap = styled.div`
  width: 100%;
  position: relative;

  ${media.tablet`
    width: 50%;
    margin-left: ${p => p.theme.setSpacing(3)}px;
  `}
`;

const ResultsMap = styled.div`
  ${p => (p.mobileStick
    ? `
      position: fixed;
      top: 64px;
      width: 100%;
      margin: 0;
      left: 0;
      padding: 0 ${p.theme.setSpacing(5)}px;
    `
    : ''
  )}
  ${media.tablet`
    position: ${p => (p.sticky ? 'fixed' : 'relative')}
    ${p => (
    p.sticky
      ? `
          position: fixed;
          top: 120px;
          right: 40px;
          width: calc((100vw/2) - 52px);
        `
      : ''
  )}
  ${p => (
    p.absolute
      ? `
          position: absolute;
          top: auto;
          bottom: 0;
          right: 0;
        `
      : ''
  )}
  `}
  ${media.desktop`
    ${p => (
    p.sticky
      ? `
          top: 40px;
          right: 80px;
          width: calc((100vw/2) - 92px);
        `
      : ''
  )}
  ${p => (
    p.absolute
      ? `
          position: absolute;
          top: auto;
          bottom: 0;
          right: 0;
          width: 100%;
        `
      : ''
  )}
  `}
  ${media.largeDesktop`
    ${p => (
    p.sticky
      ? `
          right: calc((50% - (1280px/2) + 80px));
          width: calc((1280px/2) - 92px);
        `
      : ''
  )}
  ${p => (
    p.absolute
      ? `
          position: absolute;
          top: auto;
          bottom: 0;
          right: 0;
        `
      : ''
  )}
  `}
`;

const ResultsHeading = styled.h3`
  color: ${p => p.theme.colors.textSecondary};
  font-size: ${p => p.theme.fontSize.small}px;
  font-weight: ${p => p.theme.fontWeight.thin};

  ${media.tablet`
    font-size: ${p => p.theme.fontSize.large}px;
    font-weight: ${p => p.theme.fontWeight.semiBold};
  `}
`;

const ResultsHeadingSmall = styled(ResultsHeading)`
  ${media.tablet`
    margin: ${p => p.theme.setSpacing(2)}px 0;
    font-size: ${p => p.theme.fontSize.medium}px;
  `}
`;

const renderEmptyState = (text: string, logo: string) => {
  const src = (logo === 'leaf') ? leafImg : truckImg;

  return (
    <EmptyStateWrapper>
      <EmptyStateImg alt={logo} src={src} />
      <EmptyStateText>{text}</EmptyStateText>
    </EmptyStateWrapper>
  );
};

type Props = {
  entries: any,
  isEmptyState: boolean,
  isNoResultsState: boolean,
  activeTab: string,
}

type State = {
  activeEntry: Object,
  mapSticky: boolean,
  mapAbsolute: boolean,
  mobileStick: boolean,
}

export class ResultsContent extends PureComponent<Props, State> {
  state = {
    activeEntry: {},
    mapSticky: false,
    mapAbsolute: false,
    mobileStick: false,
  }

  componentDidMount() {
    const { entries } = this.props;
    this.setState(() => ({ activeEntry: entries[0] }));
  }

  setActiveEntry = (entry: Object) => {
    this.setState(() => ({ activeEntry: entry }));
  }

  renderResultsHeading = () => {
    const { entries } = this.props;
    return `${entries.length} Programs Found`;
  }

  setStickyMap = (props: Object) => {
    const { mapAbsolute } = this.state;
    const { entries } = this.props;

    if (window.innerWidth > 768) {
      if (props.waypointTop < 30
        && mapAbsolute === false
        && entries.length > 3) {
        this.setState(() => ({ mapSticky: true }));
      }
    } else {
      // eslint-disable-next-line
      if (props.waypointTop < 30
        && entries.length > 2) {
        this.setState(() => ({ mobileStick: true }));
      }
    }
  };

  disableStickyMap = () => {
    const { mobileStick } = this.state;
    if (window.innerWidth > 768) {
      this.removeStickyMap();
    } else if (mobileStick) {
      this.removeMobileStick();
    }
  }

  watchMapBotton = (props: Object) => {
    const { entries } = this.props;
    if (window.innerWidth > 768) {
      if (props.currentPosition === 'inside'
          && entries.length > 3) {
        this.setState(() => ({ mapAbsolute: true }));
      } else {
        this.setState(() => ({ mapAbsolute: false }));
      }
    }
  };

  removeStickyMap = () => this.setState(() => ({ mapSticky: false }));

  removeMobileStick = () => this.setState(() => ({ mobileStick: false }));


  renderSelectedTab = () => {
    const { entries, activeTab } = this.props;
    const {
      activeEntry,
      mapSticky,
      mapAbsolute,
      mobileStick,
    } = this.state;

    return activeTab === MAP_TAB
      ? (
        <Fragment>
          <ResultsHeading>{this.renderResultsHeading()}</ResultsHeading>
          <Waypoint
            onEnter={() => this.disableStickyMap()}
            onLeave={props => this.setStickyMap(props)}
            topOffset='30px'
          />
          <ResultsFlexWrap>
            <MapList>
              {entries.map(entry => (
                <MapSingleResult
                  key={uniqid()}
                  entry={{ ...entry }}
                  isActive={activeEntry.efroid === entry.efroid}
                  onResultClick={item => this.setActiveEntry(item)}
                />
              ))}
            </MapList>
            <RelativeMapWrap>
              <ResultsMap
                sticky={mapSticky}
                absolute={mapAbsolute}
                mobileStick={mobileStick}
              >
                <MapComponent
                  sticky={mapSticky}
                  entries={entries}
                  activeEntry={activeEntry}
                  onMarkerClick={entry => this.setActiveEntry(entry)}
                />
              </ResultsMap>
            </RelativeMapWrap>
          </ResultsFlexWrap>
          <Waypoint
            onPositionChange={props => this.watchMapBotton(props)}
          />
        </Fragment>
      )
      : (
        <Fragment>
          <ResultsHeadingSmall>{this.renderResultsHeading()}</ResultsHeadingSmall>
          <ListColumns />
          <ResultsFlexWrap>
            <FullWidthList>
              {entries.map(entry => (
                <ListSingleResult
                  key={uniqid()}
                  entry={{ ...entry }}
                  isActive={activeEntry.efroid === entry.efroid}
                  onResultClick={item => this.setActiveEntry(item)}
                />
              ))}
            </FullWidthList>
          </ResultsFlexWrap>
        </Fragment>
      );
  }

  render() {
    const {
      isEmptyState,
      isNoResultsState,
      entries,
    } = this.props;

    return (
      <ContentContainer>
        {isEmptyState
          && renderEmptyState('No searches yet.', 'truck')
        }
        {isNoResultsState
          && renderEmptyState('No results found.', 'leaf')
        }
        {(entries && entries.length > 0)
          && this.renderSelectedTab()
        }
      </ContentContainer>
    );
  }
}
