// @flow
import React, { Fragment } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { Slide } from './slide';
import '../../assets/css/slick.css';
import '../../assets/css/slick-theme.css';
import { sizes } from '../../theme/media-queries';

const CarouselSection = styled.div`
  max-width: 100%;
  padding: 0;
  margin-top: ${p => p.theme.setSpacing(5)}px;
`;

type Props = {
  slides: Array<any>,
};

export const Carousel = (props: Props) => {
  const { slides } = props;

  const orderedSlides = slides && slides.sort((a, b) => {
    if (a.fields && a.fields.position && b.fields && b.fields.position) {
      return (a.fields.position > b.fields.position) ? 1 : -1;
    }
    return 0;
  });

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [{
      breakpoint: sizes.desktop,
      settings: {
        dots: true,
        swipeToSlide: true,
        arrows: false,
      },
    }],
  };
  return (
    <Fragment>
      <CarouselSection>
        <Slider {...settings}>
          {(orderedSlides.length > 0) && orderedSlides.map(({ fields, sys }) => (
            <Slide
              key={sys.id}
              {...fields}
            />
          ))}
        </Slider>
      </CarouselSection>
    </Fragment>
  );
};
