import React, { Fragment } from 'react';
import ReactGA from 'react-ga';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';
import { GlobalStyle } from './theme/global';
import Router from './router';

require('es6-promise').polyfill();
require('isomorphic-fetch');

ReactGA.initialize('UA-137812094-1');
ReactGA.pageview(window.location.pathname + window.location.hash);

export default () => (
  <ThemeProvider theme={theme}>
    <Fragment>
      <GlobalStyle />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </Fragment>
  </ThemeProvider>
);
