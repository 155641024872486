// @flow

import React from 'react';
import styled from 'styled-components';
import { media } from '../../theme';
import { Bubble } from './bubble';

const SectionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: ${p => p.theme.setSpacing(4)}px;

  ${media.tablet`
    padding: ${p => p.theme.setSpacing(6)}px 0;
    flex-wrap: nowrap;
  `}
`;

const Line = styled.div`
  display: none;
  width: 100%;
  height: 2px;
  background-color: ${p => p.theme.colors.borderQuaternary};
  align-self: center;

  ${media.tablet`
    display: inline-block;
  `}
`;

type Props = {
  bubbleDiagram: {
    fields: {
      statistics: Array<Object>,
    }
  },
}

export const AboutDiagram = (props: Props) => {
  const {
    bubbleDiagram: {
      fields: {
        statistics,
      },
    },
  } = props;

  const orderedStats = statistics && statistics.sort((a, b) => {
    if (a.fields && a.fields.order && b.fields && b.fields.order) {
      return (a.fields.order > b.fields.order) ? 1 : -1;
    }
    return 0;
  });

  return (
    <SectionContainer>
      { (orderedStats && orderedStats[0])
        && <Bubble {...orderedStats[0]} />
      }
      <Line />
      { (orderedStats && orderedStats[1])
        && <Bubble {...orderedStats[1]} />
      }
      <Line />
      { (orderedStats && orderedStats[2])
        && <Bubble {...orderedStats[2]} />
      }
    </SectionContainer>
  );
};
