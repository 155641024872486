// @flow

import React, { Fragment, PureComponent } from 'react';
import { HeroBanner } from '../hero-banner';
import { LoaderComponent } from '../loader';
import { fetchEFPIntro } from '../../services/api';
import { renderDefaultErrorModal } from '../error-modal';
import { Form } from './form';

type State = {
  introParagraph: string,
  isLoading: boolean,
  error: boolean,
}

export class EFPDirectory extends PureComponent<{}, State> {
  state = {
    introParagraph: '',
    isLoading: true,
    error: false,
  }

  componentDidMount() {
    fetchEFPIntro()
      .then((response) => {
        const { fields } = response.items[0];
        this.setIntro(fields.introParagraph);
      })
      .catch(this.catchError);
  }

  catchError = () => this.setState(() => ({ error: true, isLoading: false }))

  closeModal = () => this.setState(() => ({ error: false }));

  setIntro = (introParagraph: string) => this.setState(() => ({
    introParagraph,
    isLoading: false,
  }));

  renderLoadedContent = () => {
    const {
      introParagraph,
      error,
    } = this.state;

    return (
      <Fragment>
        <HeroBanner
          title='EFP Directory'
          text={introParagraph}
        />
        <Form />
        {renderDefaultErrorModal(error, this.closeModal)}
      </Fragment>
    );
  }

  render() {
    const { isLoading } = this.state;

    return isLoading ? <LoaderComponent /> : this.renderLoadedContent();
  }
}
