// @flow
import React, { PureComponent, Fragment } from 'react';
import styled from 'styled-components';
import { fetchResourcesPage } from '../../services/api';
import { HeroBanner } from '../hero-banner';
import { ContentContainer } from '../content-container';
import { CategoryMenu } from './category-menu';
import { Category } from './category';
import { media } from '../../theme';
import { renderDefaultErrorModal } from '../error-modal';
import { Carousel } from '../carousel';
import { LoaderComponent } from '../loader';

const ResourcesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: ${p => p.theme.setSpacing(10)}px;

  ${media.desktop`
    flex-wrap: nowrap;
    padding: ${p => p.theme.setSpacing(10)}px 0;
    justify-content: space-between;
  `}
`;

const LeftSection = styled.div`
  width: 100%;

  ${media.desktop`
    min-width: ${p => p.theme.setSpacing(25)}px;
    max-width: ${p => p.theme.setSpacing(25)}px;
    padding: 0;
  `}
`;

const RightSection = styled.div`
  flex-grow: 1;
  padding: ${p => p.theme.setSpacing(2)}px 0;

  ${media.tablet`
    max-width: 700px;
  `}

  ${media.desktop`
    max-width: 900px;
    padding: 0;
    padding-left: ${p => p.theme.setSpacing(5)}px;
    width: 100%;
  `}
`;

export const createID = (str: string) => {
  let newStr = str.replace(/\s+/g, '-').toLowerCase();
  if (newStr.endsWith('-')) {
    newStr = newStr.substring(0, newStr.length - 1);
  }

  return newStr;
};

type State = {
  categories: Array<any>,
  slides: Array<any>,
  introParagraph: string,
  error: boolean,
  isSidebarSticky: boolean,
  isMobileSidebarSticky: boolean,
  activeSidebarItem: string,
  isLoading: boolean,
};

export class Resources extends PureComponent<{}, State> {
  state = {
    categories: [],
    slides: [],
    introParagraph: '',
    error: false,
    isSidebarSticky: false,
    isMobileSidebarSticky: false,
    activeSidebarItem: '',
    isLoading: true,
  };

  componentDidMount() {
    fetchResourcesPage()
      .then((response) => {
        const { items } = response;
        const { fields } = items[0];

        this.setPage(
          fields.introParagraph,
          fields.carouselSlides,
          fields.resourceCategories,
        );

        window.addEventListener('scroll', this.handlePageScroll);
      })
      .catch(this.catchError);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handlePageScroll);
  }

  handlePageScroll = () => {
    const {
      isSidebarSticky,
      isMobileSidebarSticky,
      categories,
    } = this.state;

    // Desktop
    // $FlowFixMe
    const sidebarDistanceFromTop = document.getElementById('category-sidebar').getBoundingClientRect();

    if (sidebarDistanceFromTop.top < 40) {
      this.setState(() => ({ isSidebarSticky: true }));
    }

    if (isSidebarSticky && window.scrollY < 1001) {
      this.setState(() => ({ isSidebarSticky: false }));
    }

    // Mobile
    // $FlowFixMe
    const mobilePickerDistanceFromTop = document.getElementById('category-sidebar-mobile').getBoundingClientRect();

    if (mobilePickerDistanceFromTop.top < 64) {
      this.setState(() => ({
        isMobileSidebarSticky: true,
      }));
    }

    if (isMobileSidebarSticky && window.scrollY < 740) {
      this.setState(() => ({
        isMobileSidebarSticky: false,
      }));
    }

    // Active States on Sidebar Items
    const orderedCategories = categories && categories.sort((a, b) => {
      if (a.fields && a.fields.order && b.fields && b.fields.order) {
        return (a.fields.order > b.fields.order) ? 1 : -1;
      }

      return 0;
    });

    const sectionIds = [];
    orderedCategories.forEach((cat) => {
      sectionIds.push(createID(cat.fields.categoryName));
    });

    const isInViewport = (sectionId) => {
      const el = document.getElementById(`${sectionId}-heading`);
      // $FlowFixMe
      const bounding = el.getBoundingClientRect();

      const windowHeight = window.innerHeight
        || (document.documentElement && document.documentElement.clientHeight); // $FlowFixMe
      const windowWidth = window.innerWidth
        || (document.documentElement && document.documentElement.clientWidth); // $FlowFixMe

      return (
        bounding.top >= 0
          && bounding.left >= 0 // $FlowFixMe
          && bounding.bottom <= windowHeight // $FlowFixMe
          && bounding.right <= windowWidth
      );
    };

    sectionIds.forEach((id) => {
      if (isInViewport(id)) {
        this.setState(() => ({ activeSidebarItem: `${id}-selector` }));
      }
    });
  }

  setPage = (
    introParagraph: string,
    slides: Array<Object>,
    categories: Array<Object>,
  ) => this.setState(() => {
    const orderedCategories = categories && categories.sort((a, b) => {
      if (a.fields && a.fields.order && b.fields && b.fields.order) {
        return (a.fields.order > b.fields.order) ? 1 : -1;
      }

      return 0;
    });

    const activeSidebarItem = `${createID(orderedCategories[0].fields.categoryName)}-selector`;

    return {
      introParagraph,
      slides,
      categories,
      isLoading: false,
      activeSidebarItem,
    };
  });

  catchError = () => this.setState(() => ({
    error: true,
    isLoading: false,
  }))

  closeModal = () => this.setState(() => ({ error: false }));

  renderLoadedContent = () => {
    const {
      introParagraph,
      categories,
      slides,
      error,
      isSidebarSticky,
      isMobileSidebarSticky,
      activeSidebarItem,
    } = this.state;

    const orderedCategories = categories && categories.sort((a, b) => {
      if (a.fields && a.fields.order && b.fields && b.fields.order) {
        return (a.fields.order > b.fields.order) ? 1 : -1;
      }

      return 0;
    });

    return (
      <Fragment>
        <HeroBanner
          title='Resources'
          text={introParagraph}
        />
        {(slides.length > 0)
          && <Carousel slides={slides} />
        }
        <ContentContainer>
          <ResourcesContainer>
            <LeftSection>
              {(Array.isArray(categories) && categories.length > 0) && (
                <CategoryMenu
                  activeSidebarItem={activeSidebarItem}
                  isMobileSidebarSticky={isMobileSidebarSticky}
                  isSidebarSticky={isSidebarSticky}
                  menuItems={orderedCategories}
                />
              )}
            </LeftSection>
            <RightSection>
              {(orderedCategories.length > 0) && orderedCategories.map((category: Object) => (
                <div
                  key={category.fields.categoryName}
                  id={createID(category.fields.categoryName)}
                >
                  <Category
                    category={category}
                    id={createID(category.fields.categoryName)}
                  />
                </div>
              ))}
            </RightSection>
          </ResourcesContainer>
          {renderDefaultErrorModal(error, this.closeModal)}
        </ContentContainer>
      </Fragment>
    );
  }

  render() {
    const { isLoading } = this.state;

    return isLoading ? <LoaderComponent /> : this.renderLoadedContent();
  }
}
