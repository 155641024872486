// @flow

import React from 'react';
import styled from 'styled-components';
import uniqid from 'uniqid';
import { ModalComponent } from '../modal';
import { media } from '../../theme';

const ThankYouContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
`;

const ModalInner = styled.div`
  background-color: ${p => p.theme.colors.backgroundQuinary};
  width: 300px;
  height: 242px;
  position: relative;

  ${media.desktop`
    width: 600px;
  `}
`;

const ThankYouText = styled.h2`
  color: ${p => p.theme.colors.textSenary};
  font-size: ${p => p.theme.fontSize.medium}px;
  line-height: ${p => p.theme.lineHeight.small};
  text-align: center;
  display: block;
  width: 100%;
  margin: 0;

  ${media.desktop`
    font-size: ${p => p.theme.fontSize.large}px;
  `}
`;

const ThankYouWrapper = styled.div`
  display: block;
`;

type Props = {
  submitted: boolean,
  closeModal: Function,
};

export const ThankYouModal = (props: Props) => {
  const { submitted, closeModal } = props;

  const renderedMessage = [
    'Thank you for submitting.',
    'We\'ll be in touch soon!',
  ];

  return (
    <ModalComponent
      isModalOpen={submitted}
      closeModal={closeModal}
    >
      <ModalInner>
        <ThankYouContent>
          <ThankYouWrapper>
            {renderedMessage.map(text => <ThankYouText key={uniqid()}>{text}</ThankYouText>)}
          </ThankYouWrapper>
        </ThankYouContent>
      </ModalInner>
    </ModalComponent>
  );
};
